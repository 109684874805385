import React from "react";
import TopNav from "../TopNav";
import Footer from "../Footer";
import Form from "../Comp/Form";
import Header from "../Comp/Header";
import defaultImage from "../../assets/Silhouette-banner-1.webp";
import EventDetails from "../Comp/EventDetails";
import Pagination from "../Comp/Pagination";
import img1 from "../../assets/istockphoto-1217131611-612x612.jpg";

function Childhood() {
  return (
    <>
      <div>
        <TopNav transparent />
        <Header leftTitle="Childhood Disorders" breadcrumbTitle="Expertise" />
        {/* Image */}
        <div className="flex justify-center items-center py-10 mt-4 px-4 sm:px-8 md:px-16 lg:px-32 h-full w-full">
          <img
            src={img1}
            alt="Centered Example"
            className="object-fill rounded-xl shadow-xl shadow-[#f7ece1]"
          />
        </div>

        {/* Text */}
        <div className="py-10 px-4 sm:px-8 md:px-16 lg:px-32">
          <div className="max-w-4xl mx-auto">
            <h1 className="text-2xl font-extrabold mb-6">
              Childhood Disorders
            </h1>
            <p className="text-black mb-4">
              <strong>Understanding and Supporting Childhood Disorders</strong>
            </p>
            <p className="text-black mb-6">
              Childhood is a time of discovery, growth, and endless
              possibilities. However, for some children, it can also be a period
              of unique challenges that require careful attention and
              understanding. At Mindful Connection Hub, we are committed to
              providing empathetic and professional care to children facing a
              range of developmental, emotional, and behavioral disorders. Our
              goal is to empower families with the tools they need to nurture
              their children’s potential.
            </p>

            <h4 className="text-lg font-semibold mb-3">
              Recognizing Childhood Disorders
            </h4>
            <p className="text-black mb-6">
              Every child is unique, and so are their experiences and struggles.
              Sometimes, what might appear as "just a phase" can be an
              indication of a deeper issue that requires intervention. Disorders
              like Autism Spectrum Disorder (ASD),
              Attention-Deficit/Hyperactivity Disorder (ADHD), speech and
              language delays, and anxiety disorders are more common than many
              realize. Early recognition and intervention are key to helping
              children thrive despite these challenges.
            </p>

            <h4 className="text-lg font-semibold mb-3">How We Help</h4>
            <p className="text-black mb-6">
              At Mindful Connection Hub, we offer a safe and supportive
              environment tailored to meet the needs of every child. Our team of
              experts, including psychologists, speech therapists, and behavior
              specialists, works collaboratively to create personalized
              treatment plans that address the specific needs of your child.
            </p>

            <h5 className="text-md font-semibold mb-3">
              Our Services Include:
            </h5>
            <ul className="list-disc list-inside text-black mb-6">
              <li>
                <strong>Comprehensive Assessments:</strong> Thorough evaluations
                to understand your child’s strengths and areas of concern.
              </li>
              <li>
                <strong>Individualized Therapy:</strong> One-on-one sessions
                focusing on specific developmental or behavioral goals.
              </li>
              <li>
                <strong>Group Therapy:</strong> Interactive sessions that
                encourage social skills, communication, and teamwork.
              </li>
              <li>
                <strong>Parental Guidance and Support:</strong> Equipping
                families with strategies to reinforce progress at home.
              </li>
            </ul>

            <h4 className="text-lg font-semibold mb-3">
              Empathy at the Core of Our Approach
            </h4>
            <p className="text-black mb-6">
              We understand the emotional toll that childhood disorders can have
              on families. At Mindful Connection Hub, we approach every child
              and family with compassion and respect. Our team listens to your
              concerns, celebrates your child’s milestones, and stands by your
              side every step of the way.
            </p>

            <h4 className="text-lg font-semibold mb-3">Signs to Watch For</h4>
            <p className="text-black mb-6">
              While every child develops at their own pace, some signs may
              indicate the need for professional support:
            </p>
            <ul className="list-disc list-inside text-black mb-6">
              <li>Difficulty in communication or delayed speech</li>
              <li>Challenges in focusing or completing tasks</li>
              <li>Avoidance of social interactions or extreme shyness</li>
              <li>Repetitive behaviors or intense fixations</li>
              <li>Persistent feelings of sadness or anxiety</li>
            </ul>
            <p className="text-black mb-6">
              If you notice these signs or have concerns about your child’s
              development, seeking early intervention can make a world of
              difference.
            </p>

            <h4 className="text-lg font-semibold mb-3">
              A Journey Toward Growth and Possibility
            </h4>
            <p className="text-black mb-6">
              We believe that every child has the potential to flourish, given
              the right support and opportunities. Our mission at Mindful
              Connection Hub is to help unlock that potential and create a
              brighter future for your child and your family.
            </p>

            <p className="text-black mb-6">
              Reach out to us today to learn more about our programs and how we
              can support your journey. Together, we can make a meaningful
              difference in your child’s life.
            </p>
          </div>
        </div>

        <Pagination
          prevLink="/psychological-disorder"
          prevText="Psychological Disorder"
          nextLink="/special-needs-disorder"
          nextText="Special Needs Disorder"
        />
        <Form />
        <Footer />
      </div>
    </>
  );
}

export default Childhood;
