/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import TopNav from "./TopNav";
import Footer from "./Footer";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import img2 from "../assets/Untitled-design-8.webp";
import img3 from "../assets/Psychological.webp";
import imgcounseling from "../assets/male-psychologist-taking-notes-couple-therapy-session-help-with-relationship-issues-counselor-giving-advice-explaining-marriage-problems-using-paper-psychoanalysis-close-up.jpg"
import img4 from "../assets/learning-disabilities.jpg";
import img5 from "../assets/360_F_588397002_iHlsXnpbCdBkkwQ9okBaReQkybMZseRc.jpg";
import img6 from "../assets/Green Modern Health YouTube Thumbnail.jpg";
import img0 from "../assets/office-supplies-with-compute.jpg";
import img7 from "../assets/health-problems.jpg";
import img9 from "../assets/anxiety-inducing-imagery-with-angst-feelings.jpg";
import img10 from "../assets/photo-1522202176988-66273c2fd55f.avif";
import img8 from "../assets/IMG-20241219-WA0002.jpg";
import imgx from "../assets/1b9cb0d9-3921-4ffd-bdc1-78b039e7fe3f-removebg-preview.png";
import imgy from "../assets/pixelcut-export__17_-removebg.png";
import InfoSection from "./Comp/InfoSection";
import "../style/style.css";
export default function Home() {
  const [showThumbnail, setShowThumbnail] = useState(true);

  const handleThumbnailClick = () => {
    setShowThumbnail(false);
  };

  const [activeTab, setActiveTab] = useState("Psychotherapy");

  // Custom names for each tab
  const tabNames = [
    "Psychotherapy",
    "Couple Therapy",
    "Counseling",
    "Speech Therapy",
    "Dialectical Behavior Therapy (DBT)",
    "Cognitive Behavior Therapy (CBT)",
    "Anger and Stress Management",
    "Eye Movement Desensitization and Reprocessing (EMDR)",
  ];

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const questions = [
    {
      question: "What services do you offer?",
      answer:
        "We are here to support you with a wide range of services, including individual therapy, couple counseling, family therapy, speech therapy, and assistance for children with special needs. Whatever your concern, we are ready to listen and help.",
    },
    {
      question: "Who can benefit from therapy?",
      answer:
        "Therapy is for everyone. Whether you are facing emotional struggles, relationship challenges or simply seeking personal growth, We are here to walk with you every step of the way.",
    },
    {
      question: "How do I know which therapy is right for me?",
      answer:
        "You dont have to figure this out alone. During our initial session we will discuss your concerns and recommend the best approach for your sitiation.",
    },
    {
      question: "Do you work with special needs children?",
      answer:
        "Yes, We are committed to helping children with autism, ADHD, speech delays and other developmental neeeds. Every child is unique and we celebrate their strength while supporting their growth.",
    },
    {
      question: "Is therapy confidential?",
      answer:
        "Absolutely. Your privacy is our top priority. What you share stays between us, except in rare situations involving safety concerns. We are here to provide a judgement-free, secure environment. ",
    },
    {
      question: "How do I book an appointment?",
      answer:
        "Booking is simple and flexible. You can schedule an appointment online through our website, or by calling us directly. We are here to make the process as easy as possible for you. ",
    },
    {
      question: "Do you offer online therapy options?",
      answer:
        "Yes, We understand that life can be busy or circumstances might limit travel. Our secure online sessions allow you to connect with us from the comfort of your own space.",
    },
    
  ];
  const slides = [
    {
      imgSrc: img8,
      title: "Mindful Connection Hub",
      subtitle: "Computer Consulting and Software Development",
      description:
        "Connecting psychology with technology to enhance your well-being.<br>Explore our range of personalized therapeutic services, designed to support you in achieving a balanced and fulfilling life.",
      buttonText: "Book Now",
      buttonLink: "./booknow",
    },
  ];

  return (
    <>
      <TopNav />
      <main>
        <section id="carouselHeader" className="relative overflow-hidden">
          <div className="relative h-[800px]">
            <div className="relative w-full h-full overflow-hidden">
              {slides.map((slide, index) => (
                <div
                  key={index}
                  className={`absolute inset-0 w-full h-full ${
                    index === 0 ? "block" : "hidden"
                  }`}
                >
                  <img
                    src={slide.imgSrc}
                    className="w-full h-full object-cover zoom-animation"
                    alt="Carousel Slide"
                  />
                  <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-60">
                    <div className="container mx-auto text-center px-4">
                      <h1 className="text-white font-semibold mt-10 text-2xl sm:text-4xl md:text-5xl lg:text-6xl">
                        {slide.title}
                      </h1>
                      {slide.description && (
                        <p
                          className="mt-8 text-gray-50 text-sm md:text-base lg:text-xl"
                          dangerouslySetInnerHTML={{
                            __html: slide.description,
                          }}
                        ></p>
                      )}
                      {slide.buttonText && (
                        <a
                          href={slide.buttonLink}
                          className="inline-block border-2 border-transparent rounded-md bg-gradient-to-r from-[#71a371] to-[#61987a] text-white text-sm font-medium uppercase tracking-widest py-3 px-8 mt-12 hover:bg-[#875f91] hover:from-transparent hover:to-transparent hover:text-white transition-colors duration-200 ease-in-out"
                        >
                          {slide.buttonText}
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className=" relative bg-[#e5ebc7]">
          {/* Background Image with Overlay */}
          <div
            className="absolute inset-0 bg-cover"
            style={{ backgroundImage: `url(${img0})` }} // Add the background image here
          >
            
          </div>

          {/* Content Section */}
          <div className="relative container mx-auto px-4 py-10 2xl:py-20">
            {" "}
            {/* Ensure content is on top of overlay */}
            <div className="container mx-auto px-4">
              <div className="items-center flex flex-wrap">
                {/* Profile Image */}
                <div className="w-full md:w-4/12 ml-auto mr-auto mt-10 py-10 px-4 transition-transform duration-500 hover:scale-105">
                  <img
                    alt="Profile"
                    className="max-w-full rounded-lg shadow-lg transform transition-transform duration-1000 hover:scale-100 hover:shadow-2xl"
                    src={img2}
                  />
                </div>

                {/* Text Content */}
                <div className="w-full md:w-8/12 lg:w-5/12 ml-auto mr-auto md:px-4">
                  <div className="md:pr-10">
                    <h3 className="text-xl md:text-2xl lg:text-3xl mt-20 py-1 text-[#5c8b6e] font-semibold rounded-l-lg px-2">
                      Habiba Nazim Khan
                    </h3>
                    <p className="mt-4 text-normal md:text-lg lg:text-xl leading-relaxed px-2 text-stone-700 font-medium">
                      I am Dr. Habiba Nazim Khan, founder of Mindful Connection
                      Hub, with decade of experience in the field of psychology.
                      I specialize in a range of therapies, including
                      psychotherapy, couple therapy, interpersonal therapy, and
                      more.
                    </p>
                    <a
                      href="/about"
                      className="inline-block border-2 border-[#61987a] font-medium  text-[#61987a] text-sm uppercase tracking-widest py-3 ml-2 px-8 mt-8 hover:bg-[#61987a] hover:text-white transition-colors duration-200 ease-in-out"
                    >
                      About
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="grid grid-cols-1 lg:grid-cols-2 -mt-6">
            {/* Video Section */}
            <div className="bg-white p-8 px-12 py-20 flex justify-end items-center">
              <div className="relative w-full aspect-video lg:h-[500px]">
                {showThumbnail && (
                  <img
                    src={img6} // Thumbnail URL (YouTube provides a default at this link)
                    alt="Video Thumbnail"
                    className="absolute top-0 left-0 w-full h-full object-cover cursor-pointer"
                    onClick={handleThumbnailClick}
                  />
                )}
                {!showThumbnail && (
                  <iframe
                    src="https://www.youtube.com/embed/vKFqukLT9C4?autoplay=1" // Autoplay on click
                    className="w-full h-full"
                    title="YouTube video player"
                    allowFullScreen
                  ></iframe>
                )}
              </div>
            </div>

            {/* Experience Section */}
            <div className="p-8 sm:p-12 md:p-10 2xl:p-20 flex justify-start ">
              <div className="w-full xl:max-w-4xl">
                <div className="w-24 h-2 bg-[#5d9676] mb-4"></div>
                <h2 className="font-display font-bold text-2xl md:text-3xl lg:text-4xl mb-12">
                  Bringing Proven Experience In...
                </h2>
                <div className="md:flex max-w-3xl relative">
                  {/* Tabs Section */}
                  <div className="w-full rounded-xl shadow-xl py-6 my-6 bg-gradient-to-b from-[#71a371] to-[#61987a] md:relative z-20 md:-mt-2 md:-mr-20">
                    <ul className="flex flex-col space-y-1 text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">
                      {tabNames.map((tabName, index) => (
                        <li key={index} className="w-full">
                          <button
                            onClick={() => handleTabClick(tabName)}
                            className={`inline-flex items-center px-4 py-3 rounded-lg w-full text-left ${
                              activeTab === tabName
                                ? "text-white bg-[#75b575]"
                                : "text-gray-100 hover:bg-[#75b575] dark:hover:bg-[#333533] dark:hover:text-gray-500"
                            }`}
                          >
                            {tabName}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>

                  {/* Tab Content Section */}
                  <div className="p-6 md:pl-20 bg-[#d6e8d6] text-medium rounded-xl shadow-2xl text-gray-500 dark:text-gray-400 dark:bg-gray-800 md:rounded-tr-lg md:rounded-br-lg w-full md:mt-16 md:ml-4 relative z-10">
                    {activeTab === "Psychotherapy" && (
                      <div>
                        <h3 className="text-lg font-bold text-gray-900 dark:text-white mb-4">
                          Psychotherapy
                        </h3>
                        <p className="mb-2">
                          Psychotherapy involves guided conversations and
                          therapeutic techniques to help individuals manage
                          emotional, behavioral, and mental health challenges.
                        </p>
                        <p className="mb-4">
                          {" "}
                          It can address issues like anxiety, depression,
                          trauma, and stress, empowering individuals to
                          understand and change patterns that impact their
                          well-being.
                        </p>
                        <a
                          href="/psychotherapy"
                          className="hover:text-[#75b575] duration-300 ease-in-out"
                        >
                          Read more →
                        </a>
                      </div>
                    )}
                    {activeTab === "Couple Therapy" && (
                      <div>
                        <h3 className="text-lg font-bold text-gray-900 dark:text-white mb-4">
                          Couple Therapy
                        </h3>
                        <p className="mb-2">
                          Couple therapy is a form of counseling designed to
                          help couples improve their relationship by addressing
                          various emotional and psychological issues. It helps
                          couples navigate challenges.
                        </p>
                        <p className="mb-4">
                          By focusing on the dynamics between partners, couple
                          therapy aims to rebuild intimacy, strengthen the
                          emotional connection and fulfilling relationship.
                        </p>
                        <a
                          href="/coupletherapy"
                          className="hover:text-[#75b575] duration-300 ease-in-out"
                        >
                          Read more →
                        </a>
                      </div>
                    )}
                    {activeTab === "Counseling" && (
                      <div>
                        <h3 className="text-lg font-bold text-gray-900 dark:text-white mb-4">
                          Counseling
                        </h3>
                        <p className="mb-2">
                          Counseling offers a safe space for individuals to
                          address personal, emotional, or psychological
                          concerns. It helps clients understand their feelings,
                          develop coping mechanisms, and work towards making
                          positive changes to enhance their overall mental
                          health.
                        </p>
                        <a
                          href="/counseling"
                          className="hover:text-[#75b575] duration-300 ease-in-out"
                        >
                          Read more →
                        </a>
                      </div>
                    )}
                    {activeTab === "Speech Therapy" && (
                      <div>
                        <h3 className="text-lg font-bold text-gray-900 dark:text-white mb-4">
                          Speech Therapy
                        </h3>
                        <p className="mb-2">
                          Speech therapy is a specialized treatment that helps
                          individuals with speech, language, and communication
                          challenges.
                        </p>
                        <p className="mb-2">
                          It is beneficial for those with speech delays,
                          stuttering, articulation issues, voice disorders.
                        </p>
                        <p className="mb-4">
                          The therapy may also address difficulties with
                          understanding and expressing thoughts, as well as
                          social communication skills.
                        </p>
                        <a
                          href="/speechtherapy"
                          className="hover:text-[#75b575] duration-300 ease-in-out"
                        >
                          Read more →
                        </a>
                      </div>
                    )}
                    {activeTab === "Dialectical Behavior Therapy (DBT)" && (
                      <div>
                        <h3 className="text-lg font-bold text-gray-900 dark:text-white mb-4">
                          Dialectical Behavior Therapy (DBT)
                        </h3>
                        <p className="mb-2">
                          Dialectical Behavior Therapy (DBT) is a form of
                          cognitive-behavioral therapy that focuses on helping
                          individuals manage intense emotions and improve
                          interpersonal relationships.
                        </p>
                        <p className="mb-4">
                          The therapy encourages acceptance of difficult
                          emotions while also promoting change through practical
                          skills and strategies.
                        </p>
                        <a
                          href="/dbt"
                          className="hover:text-[#75b575] duration-300 ease-in-out"
                        >
                          Read more →
                        </a>
                      </div>
                    )}
                    {activeTab === "Cognitive Behavior Therapy (CBT)" && (
                      <div>
                        <h3 className="text-lg font-bold text-gray-900 dark:text-white mb-4">
                          Cognitive Behavior Therapy (CBT)
                        </h3>
                        <p className="mb-4">
                          Cognitive Behavioral Therapy (CBT) is a structured,
                          goal-oriented therapeutic approach that helps
                          individuals identify and change negative thought
                          patterns. and behaviors that contribute to emotional
                          distress.
                        </p>
                        <p className="mb-4">
                          CBT is effective for treating a wide range of mental
                          health issues, including anxiety, depression, PTSD,
                          and OCD.
                        </p>
                        <a
                          href="/cbt"
                          className="hover:text-[#75b575] duration-300 ease-in-out"
                        >
                          Read more →
                        </a>
                      </div>
                    )}
                    {activeTab ===
                      "Eye Movement Desensitization and Reprocessing (EMDR)" && (
                      <div>
                        <h3 className="text-lg font-bold text-gray-900 dark:text-white mb-4">
                          Eye Movement Desensitization and Reprocessing (EMDR)
                        </h3>
                        <p className="mb-2">
                          Eye Movement Desensitization and Reprocessing (EMDR)
                          is a psychotherapy technique designed to help
                          individuals process and heal from traumatic memories
                          and experiences.
                        </p>
                        <p className="mb-4">
                          This process helps to integrate the traumatic
                          memories, reducing their emotional charge.
                        </p>
                        <a
                          href="/emdr"
                          className="hover:text-[#75b575] duration-300 ease-in-out"
                        >
                          Read more →
                        </a>
                      </div>
                    )}
                    {activeTab === "Anger and Stress Management" && (
                      <div>
                        <h3 className="text-lg font-bold text-gray-900 dark:text-white mb-4">
                          Anger and Stress Management
                        </h3>
                        <p className="mb-2">
                          Anger and stress management as a service provides
                          individuals with tools, techniques and support to
                          reduce feelings of anger and stress, aiming to improve
                          mental well-being and overall quality of life.
                        </p>
                        <p className="mb-2">
                          These services are typically structured around
                          evidence-based practices and therapeutic approaches
                          that are customized to meet each client's unique
                          needs.
                        </p>
                        <a
                          href="/stressmanage"
                          className="hover:text-[#75b575] duration-300 ease-in-out"
                        >
                          Read more →
                        </a>
                      </div>
                    )}
                  </div>
                </div>

                <a
                  href="/services"
                  className="inline-block border-2 border-[#61987a] font-medium  text-[#61987a] text-sm uppercase tracking-widest py-3 px-8 mt-8 hover:bg-[#61987a] hover:text-white transition-colors duration-200 ease-in-out"
                >
                  Know more
                </a>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="max-w-xl mx-auto text-center py-20">
            <div className="w-24 h-2 bg-[#5d9676] mb-4 mx-auto"></div>

            <h2 className="font-display font-bold text-3xl md:text-4xl lg:text-5xl">
              Our Expertise
            </h2>
          </div>
          <div className="flex flex-wrap w-full">
            {/* Psychological Disorder Section */}
            <div className="relative w-full md:w-auto md:flex-1 flex items-center justify-center h-96 bg-black group">
              {/* Heading - Default on image */}
              <div className="absolute inset-0 flex items-center justify-center text-2xl font-medium uppercase text-white z-10 group-hover:hidden">
                Psychological Disorder
              </div>

              {/* Hover State with Background and Content */}
              <div className="absolute inset-0 bg-[#3f4140] bg-opacity-40 flex flex-col items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 text-white p-4 z-20">
                <h3 className="text-2xl font-medium uppercase mb-4">
                  Psychological Disorder
                </h3>
                <p className="text-center text-lg mb-4">
                  Explore various psychological disorders and their treatment
                  approaches.
                </p>
                <a
                  href="/psychological-disorder"
                  className="inline-flex items-center text-sm font-semibold bg-white hover:bg-[#5a826c] hover:text-white text-black py-2 px-4 rounded"
                >
                  Read More
                </a>
              </div>

              {/* Image */}
              <img
                src={img3}
                alt="Psychological Disorder"
                className="absolute inset-0 w-full h-full object-cover opacity-50 group-hover:opacity-100 transition-opacity duration-300"
              />
            </div>

            {/* Childhood Disorder Section */}
            <div className="relative w-full md:w-auto md:flex-1 flex items-center justify-center h-96 bg-black group">
              {/* Heading - Default on image */}
              <div className="absolute inset-0 flex items-center justify-center text-2xl font-medium uppercase text-white z-10 group-hover:hidden">
                Childhood Disorder
              </div>

              {/* Hover State with Background and Content */}
              <div className="absolute inset-0 bg-[#262525] bg-opacity-40 flex flex-col items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 text-white p-4 z-20">
                <h3 className="text-2xl font-medium uppercase mb-4">
                  Childhood Disorder
                </h3>
                <p className="text-center text-lg mb-4">
                  Learn about childhood disorders and the best ways to approach
                  them.
                </p>
                <a
                  href="/childhood-disorder"
                  className="inline-flex items-center text-sm font-semibold bg-white hover:bg-[#5a826c] hover:text-white text-black py-2 px-4 rounded"
                >
                  Read More
                </a>
              </div>

              {/* Image */}
              <img
                src={img4}
                alt="Childhood Disorder"
                className="absolute inset-0 w-full h-full object-cover opacity-50 group-hover:opacity-100 transition-opacity duration-300"
              />
            </div>

            {/* Special Needs Disorder Section */}
            <div className="relative w-full md:w-auto md:flex-1 flex items-center justify-center h-96 bg-black group">
              {/* Heading - Default on image */}
              <div className="absolute inset-0 flex items-center justify-center text-2xl font-medium uppercase text-white z-10 group-hover:hidden">
                Special Needs Disorder
              </div>

              {/* Hover State with Background and Content */}
              <div className="absolute inset-0 bg-[#3f4140] bg-opacity-40 flex flex-col items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 text-white p-4 z-20">
                <h3 className="text-2xl font-medium uppercase mb-4">
                  Special Needs Disorder
                </h3>
                <p className="text-center text-lg mb-4">
                  Gain insights into special needs disorders and therapeutic
                  strategies.
                </p>
                <a
                  href="/special-needs-disorder"
                  className="inline-flex items-center text-sm font-semibold bg-white hover:bg-[#5a826c] hover:text-white text-black py-2 px-4 rounded"
                >
                  Read More
                </a>
              </div>

              {/* Image */}
              <img
                src={img5}
                alt="Special Needs Disorder"
                className="absolute inset-0 w-full h-full object-cover opacity-50 group-hover:opacity-100 transition-opacity duration-300"
              />
            </div>
            {/* Counseling */}
            <div className="relative w-full md:w-auto md:flex-1 flex items-center justify-center h-96 bg-black group">
              {/* Heading - Default on image */}
              <div className="absolute inset-0 flex items-center justify-center text-2xl font-medium uppercase text-white z-10 group-hover:hidden">
                Counseling
              </div>

              {/* Hover State with Background and Content */}
              <div className="absolute inset-0 bg-[#3f4140] bg-opacity-40 flex flex-col items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 text-white p-4 z-20">
                <h3 className="text-2xl font-medium uppercase mb-4">
                Counseling
                </h3>
                <p className="text-center text-lg mb-4">
                Your Path to Emotional Healing Starts Here
                </p>
                <a
                  href="/counseling-exp"
                  className="inline-flex items-center text-sm font-semibold bg-white hover:bg-[#5a826c] hover:text-white text-black py-2 px-4 rounded"
                >
                  Read More
                </a>
              </div>

              {/* Image */}
              <img
                src={imgcounseling}
                alt="Special Needs Disorder"
                className="absolute inset-0 w-full h-full object-cover opacity-50 group-hover:opacity-100 transition-opacity duration-300"
              />
            </div>
          </div>
        </section>

        <section>
          <div className="grid grid-cols-1 md:grid-cols-2 py-20 mt-8 ">
            <div className="bg-white p-12 md:p-24 flex justify-start items-center ">
              <a href="/blog/my-third-big-post/">
                <img
                  src={img10}
                  alt="Blog post image"
                  className="w-full  max-w-xl border-2 p-2  border-[#5d9676]"
                />
              </a>
            </div>

            <div className="md:order-first bg-[#d6e8d6] p-12 md:p-24 flex justify-end items-center">
              <div className="max-w-md">
                <div className="w-24 h-2 bg-[#5d9676] mb-4"></div>
                <h2 className="font-display font-bold text-2xl md:text-3xl lg:text-4xl mb-6">
                  Blogs
                </h2>
                <p className="md:text-lg mb-6 mt-4 text-sm sm:text-base leading-relaxed  text-gray-00 font-medium text-stone-700">
                  We provide a variety of resources to support your mental
                  health journey, including articles, videos, and self-help
                  tools....
                </p>
                <a
                  href="/blogs"
                  className="inline-block border-2 border-[#61987a] font-medium  text-[#61987a] text-sm uppercase tracking-widest py-3 px-8 mt-6 hover:bg-[#61987a] hover:text-white transition-colors duration-200 ease-in-out"
                >
                  Read more
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="mb-10">
  <div className="max-w-xl mx-auto text-center md:py-6 mt-12">
    <div className="w-24 h-2 bg-[#5D9676] mb-4 mx-auto"></div>
    <h2 className="font-display font-bold text-3xl md:text-4xl lg:text-5xl mb-10">
      Meet Our Team
    </h2>
  </div>
  <div className="w-full flex flex-wrap justify-center px-4">
    {/* Team Member 1 */}
    <div className="relative group w-80 h-[400px] mx-6 mb-8">
      {/* Image */}
      <img
        alt="Habiba Nazim Khan"
        src={imgx}
        className="w-full h-full object-cover rounded-lg shadow-lg transition-all duration-300"
      />
      {/* Information Overlay */}
      <div className="absolute bottom-0 left-0 w-full bg-gradient-to-t from-black/100 to-transparent p-5 rounded-b-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300">
        <h5 className="text-white text-lg text-center font-bold mb-2">Habiba Nazim Khan</h5>
        <p className="text-gray-300 text-sm uppercase font-semibold text-center">
          Consultant Clinical Psychologist <br />
          Speech Language Pathologist <br />
          DBT Practitioner
        </p>
        <div className="mt-4 flex justify-center space-x-4">
          <a
            href="https://www.instagram.com/habibanazimkhan/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white text-2xl hover:text-[#A7CCB7] transition-colors p-2"
          >
            <FaInstagram />
          </a>
          <a
            href="https://www.linkedin.com/in/habiba-nazim-khan-6a42188b"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white text-2xl hover:text-[#A7CCB7] transition-colors p-2"
          >
            <FaLinkedin />
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=61561362483687"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white text-2xl hover:text-[#A7CCB7] transition-colors p-2"
          >
            <FaFacebook />
          </a>
        </div>
      </div>
    </div>
    {/* Team Member 2 */}
    <div className="relative group w-80 h-[400px] mx-6 mb-8">
      {/* Image */}
      <img
        alt="Zarnab Kamal"
        src={imgy}
        className="w-full h-full object-cover rounded-lg shadow-lg transition-all duration-300"
      />
      {/* Information Overlay */}
      <div className="absolute bottom-0 left-0 w-full bg-gradient-to-t from-black to-transparent p-5 rounded-b-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300">
        <h5 className="text-white text-lg text-center font-bold mb-2">Zarnab Kamal</h5>
        <p className="text-gray-300 text-sm uppercase font-semibold text-center">
          Assistant Clinical Psychologist
        </p>
        <div className="mt-4 flex justify-center space-x-4">
          <a
            href="https://www.instagram.com/syedaz.k?igsh=bzNlY3N6MXE4MGFt"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white text-2xl hover:text-[#A7CCB7] transition-colors p-2"
          >
            <FaInstagram />
          </a>
          <a
            href="https://www.linkedin.com/in/zarnab-kamal-a1208631a/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white text-2xl hover:text-[#A7CCB7] transition-colors p-2"
          >
            <FaLinkedin />
          </a>
          <a
            href="https://www.facebook.com/zarnab.kamal.5?mibextid=ZbWKwL"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white text-2xl hover:text-[#A7CCB7] transition-colors p-2"
          >
            <FaFacebook />
          </a>
        </div>
      </div>
    </div>
  </div>
</section>

        <section>
          <div className="container mx-auto px-4 md:px-8 lg:px-32 py-12">
            <h2 className="text-2xl md:text-3xl lg:text-3xl font-bold mb-10 px-2">
              Do you have any questions?
            </h2>
            <div className="space-y-2">
              {questions.map((question, index) => (
                <div
                  key={index}
                  className=" border-b-2 border-gray-200 rounded-lg p-4"
                >
                  <div
                    className="flex justify-between items-center cursor-pointer"
                    onClick={() => toggleAccordion(index)}
                  >
                    <h3 className="text-lg font-medium">{question.question}</h3>{" "}
                     
                    <span className="text-gray-500">
                      {activeIndex === index ? "▲" : "▼"}
                    </span>
                  </div>
                  {activeIndex === index && (
                    <p className="mt-2 text-gray-700">{question.answer}</p>
                  )}
                </div>
              ))}
            </div>
          </div>
        </section>
        <InfoSection />
      </main>
      <Footer />
    </>
  );
}
