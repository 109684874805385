import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import About from "./components/About";
import Services from "./components/Services";
import Resources from "./components/Resources";
import Events from "./components/Events";
import BookNow from "./components/BookNow";
import Psychotherapy from "./components/ServicePages/Psychotherapy";
import CoupleTherapy from "./components/ServicePages/CoupleTherapy";
import Interpersonal from "./components/ServicePages/Interpersonal";
import Counseling from "./components/ServicePages/Counseling";
import CareerCounseling from "./components/ServicePages/CareerCounseling";
import SpeechTherapy from "./components/ServicePages/SpeechTherapy";
import Dbt from "./components/ServicePages/Dbt";
import StressManage from "./components/ServicePages/StressManage";
import AngerManage from "./components/ServicePages/AngerManage";
import Disorder from "./components/ServicePages/Disorder";
import SpecialNeeds from "./components/ServicePages/SpecialNeeds";
import SpeechIssues from "./components/ServicePages/SpeechIssues";
import Relaxation from "./components/ServicePages/Relaxation";
import WorldMentalHealth from "./components/EventsPages/WorldMentalHealth";
import IllnesWeek from "./components/EventsPages/IllnesWeek";
import EatingDisorder from "./components/EventsPages/EatingDisorder";
import Schizophrenia from "./components/EventsPages/Schizophrenia";
import Biopolar from "./components/EventsPages/Biopolar";
import PtsdAwareness from "./components/EventsPages/PtsdAwareness";
import ChildrenWeek from "./components/EventsPages/ChildrenWeek";
import Happiness from "./components/EventsPages/Happiness";
import MentalHealth from "./components/EventsPages/MentalHealth";
import WorldSuicide from "./components/EventsPages/WorldSuicide";
import NationalSuicide from "./components/EventsPages/NationalSuicide";
import NationalMental from "./components/EventsPages/NationalMental";
import Psychological from "./components/ExpertisePages/Psychological";
import Childhood from "./components/ExpertisePages/Childhood";
import Special from "./components/ExpertisePages/Special";
import DigitalDetox from "./components/BlogsPages/DigitalDetox";
import VirtualAutism from "./components/BlogsPages/VirtualAutism";
import Contact from "./components/Contact";
import Cbt from "./components/ServicePages/Cbt";
import Emdr from "./components/ServicePages/Emdr";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TopNav from "./components/TopNav";
import CounselingExpertise from "./components/ExpertisePages/CounselingExpertise";


function App() {
  return (
    <Router>

<TopNav />
       

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/blogs" element={<Resources />} />
        <Route path="/events" element={<Events />} />
        <Route path="/booknow" element={<BookNow />} />
        <Route path="/contactus" element={<Contact />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        



        {/* Expertise Pages */}
        <Route path="/psychological-disorder" element={<Psychological />} />
        <Route path="/childhood-disorder" element={<Childhood />} />
        <Route path="/special-needs-disorder" element={<Special />} />
        <Route path="/counseling-exp" element={<CounselingExpertise />} />


        {/* Service Pages */}
        <Route path="/psychotherapy" element={<Psychotherapy />} />
        <Route path="/coupletherapy" element={<CoupleTherapy />} />
        <Route path="/interpersonal" element={<Interpersonal />} />
        <Route path="/counseling" element={<Counseling />} />
        <Route path="/career" element={<CareerCounseling />} />
        <Route path="/speechtherapy" element={<SpeechTherapy />} />
        <Route path="/dbt" element={<Dbt />} />
        <Route path="/stressmanage" element={<StressManage />} />
        <Route path="/angermanage" element={<AngerManage />} />
        <Route path="/disorder" element={<Disorder />} />
        <Route path="/speechissues" element={<SpeechIssues />} />
        <Route path="/specialneeds" element={<SpecialNeeds />} />
        <Route path="/relaxationtechniques" element={<Relaxation />} />
        <Route path="/cbt" element={<Cbt />} />
        <Route path="/emdr" element={<Emdr />} />



        {/* Resourses */}
        <Route path="/digital-detox" element={<DigitalDetox />} />
        <Route path="/virtual-autism" element={<VirtualAutism />} />



        {/* Events Pages */}
        <Route path="/mental-health-day" element={<WorldMentalHealth />} />
        <Route path="/mental-illness-awareness" element={<IllnesWeek />} />
        <Route path="/eating-disorders-awareness" element={<EatingDisorder />} />
        <Route path="/schizophrenia-awareness" element={<Schizophrenia />} />
        <Route path="/bipolar-awareness" element={<Biopolar />} />
        <Route path="/ptsd-awareness" element={<PtsdAwareness />} />
        <Route path="/child-awareness" element={<ChildrenWeek />} />
        <Route path="/happiness-day-awareness" element={<Happiness />} />
        <Route path="/mental-health-awareness" element={<MentalHealth />} />
        <Route path="/world-suicide-awareness" element={<WorldSuicide />} />
        <Route path="/national-suicide-prevention" element={<NationalSuicide />} />
        <Route path="/national-mental-health-awareness" element={<NationalMental />} />

      </Routes>
    </Router>
  );
}

export default App;
