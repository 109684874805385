import React from "react";
import TopNav from "../TopNav";
import Footer from "../Footer";
import Header from "../Comp/Header";
import EventsText from "../Comp/EventsText";
import defaultImage from "../../assets/Silhouette-banner-1.webp";
import EventDetails from "../Comp/EventDetails";
import Pagination from "../Comp/Pagination";
import img1 from "../../assets/shutterstock_1212356686.webp";

function EatingDisorder() {
  return (
    <>
      <div>
        <TopNav transparent />
        <Header
          leftTitle="Eating Disorders Awareness Week"
          breadcrumbTitle="Events"
        />
        <EventDetails
          eventImage={img1}
          title="Events"
          startDate="January 17, 2023 12:00 pm"
          endDate="June 25, 2025 10:45 pm"
          // doorTime="10:00 am"
          // status="Showing"
          address="Model Town, Haseeb Street, 42-43 B, Multan, Pakistan."
        />
        <EventsText>
          {/* Eating Disorders Awareness*/}

          <h3 className="text-2xl font-semibold mb-2 mt-4">
            Eating Disorders Awareness
          </h3>
          <p className="text-base font-normal">
            Eating Disorders Awareness Week is an annual event dedicated to
            raising awareness about eating disorders, promoting understanding,
            and providing support to individuals affected by these conditions.
            This week-long campaign aims to shed light on the complexities of
            eating disorders, including anorexia nervosa, bulimia nervosa,
            binge-eating disorder, and others. The event emphasizes the
            importance of early intervention, access to treatment, and reducing
            the stigma associated with eating disorders. It provides a platform
            for individuals to share their stories, raise awareness, and offer
            support to those in need.
          </p>
          <h3 className="text-2xl font-semibold mb-2 mt-6">
            When is Eating Disorders Awareness Week?
          </h3>
          <p className="text-base font-normal">
            Eating Disorders Awareness Week typically takes place in the last
            week of February. In 2024, it will be held from February 28th to
            March 5th, providing a dedicated time for activities and initiatives
            focused on eating disorder awareness and support.
          </p>

          {/* History of Eating Disorders Awareness Week*/}

          <h3 className="text-2xl font-semibold mb-2 mt-6">
            History of Eating Disorders Awareness Week
          </h3>
          <p className="text-base font-normal">
            Eating Disorders Awareness Week was established to address the
            increasing prevalence of eating disorders and the urgent need for
            awareness and support. The event has a history of advocacy,
            education, and empowerment, with individuals and organizations
            coming together to make a difference in the lives of those affected
            by eating disorders.
          </p>

          {/* Importance of Awareness and Effective Treatment*/}

          <h3 className="text-2xl font-semibold mb-2 mt-6">
            Importance of Awareness and Effective Treatment
          </h3>
          <p className="text-base font-normal">
            Raising awareness about eating disorders is crucial for several
            reasons:
          </p>
          <p className="text-base text-black leading-relaxed mt-4">
            <span className="text-base mb-2 text-black font-semibold">
              Early Intervention:
            </span>{" "}
            Awareness can lead to early recognition of symptoms, which is vital
            for effective treatment and recovery.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base mb-2 text-black font-semibold">
              Reducing Stigma:
            </span>{" "}
            Increasing understanding about eating disorders helps to break down
            the stigma and misconceptions surrounding these conditions,
            encouraging individuals to seek help without fear of judgment.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base mb-2 text-black font-semibold">
              Education:
            </span>{" "}
            Providing accurate information about eating disorders, their causes,
            and their effects can help individuals and communities better
            support those affected.
          </p>

          {/* Effective Treatment Options */}

          <h3 className="text-2xl font-semibold mb-2 mt-6">
            Effective Treatment Options
          </h3>
          <p className="text-base font-normal">
            Effective treatment options for eating disorders are available and
            can significantly improve outcomes. These may include:
          </p>
          <p className="text-base text-black leading-relaxed mt-4">
            <span className="text-base mb-2 text-black font-semibold">
              Therapy:
            </span>{" "}
            Various forms of therapy, such as cognitive-behavioral therapy
            (CBT), family-based therapy, and interpersonal therapy, can be
            effective in treating eating disorders.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base mb-2 text-black font-semibold">
              Medical Care:
            </span>{" "}
            Regular medical monitoring and care are essential to address the
            physical health issues that often accompany eating disorders.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base mb-2 text-black font-semibold">
              Nutritional Counseling:
            </span>{" "}
            Guidance from dietitians and nutritionists can help individuals
            develop healthy eating habits and restore nutritional balance.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base mb-2 text-black font-semibold">
              Support Groups:
            </span>{" "}
            Peer support groups provide a safe space for individuals to share
            experiences and receive encouragement from others who understand
            their struggles.
          </p>

          {/* How to Get Involved*/}

          <h3 className="text-2xl font-semibold mb-2 mt-6">
            How to Get Involved
          </h3>
          <p className="text-base font-normal">
            Participating in Eating Disorders Awareness Week is a meaningful way
            to support individuals affected by eating disorders and contribute
            to greater understanding. Here are some ways to get involved:
          </p>
          <p className="text-base text-black leading-relaxed mt-4">
            <span className="text-base mb-2 text-black font-semibold">
              Learn and Educate:
            </span>{" "}
            Take the time to educate yourself about eating disorders, their
            signs, symptoms, and available treatments. Share this knowledge with
            friends and family to increase awareness.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base mb-2 text-black font-semibold">
              Share Stories:
            </span>{" "}
            If you or someone you know has experienced an eating disorder,
            consider sharing your story to reduce stigma and provide hope to
            others. Personal narratives can be powerful tools for change.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base mb-2 text-black font-semibold">
              Support Local Events:
            </span>{" "}
            Attend local events, workshops, and seminars related to eating
            disorders during the awareness week. These events often provide
            valuable information and resources.
          </p>
          <p className="text-base text-black leading-relaxed mt-4">
            <span className="text-base mb-2 text-black font-semibold">
              Donate or Volunteer:
            </span>{" "}
            Consider donating to organizations that provide support and
            resources for individuals with eating disorders. Volunteering your
            time can also make a significant impact.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base mb-2 text-black font-semibold">
              Use Social Media:
            </span>{" "}
            Share informative posts, articles, and statistics on your social
            media platforms using event hashtags to reach a wider audience and
            promote understanding.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base mb-2 text-black font-semibold">
              Organize Awareness Activities:
            </span>{" "}
            Collaborate with local schools, community centers, or support groups
            to organize awareness activities, such as workshops, art
            exhibitions, or panel discussions.
          </p>

          {/* Event Hashtags*/}

          <h3 className="text-2xl font-semibold mb-2 mt-6">Event Hashtags</h3>
          <p className="text-base font-normal">
            When participating in Eating Disorders Awareness Week and sharing
            your support on social media, consider using event hashtags to
            connect with others who are passionate about raising awareness:
          </p>
          <ul>
            <li className="text-base font-semibold mt-4 mb-2">
              #EatingDisordersAwareness
            </li>
            <li className="text-base font-semibold mb-2">#EDAW</li>
            <li className="text-base font-semibold mb-2">#BreakTheStigma</li>
            <li className="text-base font-semibold mb-2">
              #SupportAndRecovery
            </li>
          </ul>
          <p className="text-base font-normal mt-4">
            These hashtags can help you engage in meaningful conversations,
            share resources, and show your solidarity with individuals affected
            by eating disorders.
          </p>
        </EventsText>
        <Pagination
          prevLink="/mental-illness-awareness"
          prevText="Mental Illness Awareness Week"
          nextLink="/schizophrenia-awareness"
          nextText="Schizophrenia Awareness"
        />
        <Footer />
      </div>
    </>
  );
}

export default EatingDisorder;
