import React from "react";

function EventsText({ children }) {
  return (
    <>
      <div className="flex flex-col w-full h-auto px-4 md:px-11 lg:px-32 xl:px-36 py-14 ">
        <div className="font-base text-dark">
          <div className="text-sm text-black">
            {children}{" "}
            {/* Allowing for multiple headings, paragraphs, and spans */}
          </div>
        </div>
      </div>
    </>
  );
}

export default EventsText;
