import React from "react";
import TopNav from "../TopNav";
import Footer from "../Footer";
import Header from "../Comp/Header";
import EventsText from "../Comp/EventsText";
import defaultImage from "../../assets/Silhouette-banner-1.webp";
import EventDetails from "../Comp/EventDetails";
import Pagination from "../Comp/Pagination";
import img1 from "../../assets/167-1679675_psychedelic-therapy-negative.png";

function NationalMental() {
  return (
    <>
      <div>
        <TopNav transparent />
        <Header
          leftTitle="National Mental Health Awareness Month"
          breadcrumbTitle="Events"
        />
        <EventDetails
          eventImage={img1}
          title="Events"
          startDate="May 1st"
          endDate="May 31st"
          // doorTime="10:00 am"
          // status="Showing"
          address="Model Town, Haseeb Street, 42-43 B, Multan, Pakistan."
        />
        <EventsText>
          {/*  National Mental Health Awareness Month */}

          <h3 className="text-2xl font-semibold mb-2 mt-4">
            National Mental Health Awareness Month
          </h3>
          <p className="text-base font-normal">
            <strong>National Mental Health Awareness Month</strong> is observed
            annually throughout May in both Pakistan and the United States,
            serving as a vital platform to highlight the importance of mental
            health, reduce stigma, and encourage individuals to seek help. This
            month-long initiative promotes education, advocacy, and actionable
            change to address the mental health challenges faced by people of
            all ages.
          </p>

          {/* The Significance of Mental Health Awareness Month */}
          <h3 className="text-2xl font-semibold mb-2 mt-6">
            The Significance of Mental Health Awareness Month
          </h3>
          <p className="text-base font-normal">
            Mental health affects every aspect of our lives, including
            relationships, work, and overall well-being. In{" "}
            <strong>Pakistan</strong>, mental health struggles are often
            compounded by stigma and lack of resources, with approximately{" "}
            <strong>24 million people</strong> experiencing mental health
            conditions. In the <strong>USA</strong>, mental illness impacts{" "}
            <strong>1 in 5 adults</strong>, emphasizing the need for widespread
            awareness and support.
          </p>
          <p className="text-base font-normal">
            National Mental Health Awareness Month provides an opportunity to:
          </p>

          <ul className="list-disc list-inside mt-3">
            <li className="text-base text-black leading-relaxed">
              Educate communities about mental health conditions.
            </li>
            <li className="text-base text-black leading-relaxed">
              Foster compassion and understanding for those living with mental
              illness.
            </li>
            <li className="text-base text-black leading-relaxed">
              Advocate for improved mental health policies and funding
            </li>
            <li className="text-base text-black leading-relaxed">
              Empower individuals to prioritize their mental well-being.
            </li>
          </ul>

          {/* Mental Health Challenges in Pakistan and the USA */}
          <h3 className="text-2xl font-semibold mb-2 mt-6">
            Mental Health Challenges in Pakistan and the USA
          </h3>
          <ul className="list-decimal list-inside mt-3">
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Pakistan:
              </span>{" "}
              Limited access to mental health professionals (only one
              psychiatrist per <strong>500,000 people</strong>), lack of
              awareness, and cultural stigma are significant barriers.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                USA:
              </span>{" "}
              Challenges include high treatment costs, disparities in access to
              care, and rising rates of anxiety and depression, especially among
              youth.
            </li>
          </ul>
          <p className="text-base font-normal">
            Both countries share a need for increased resources, awareness
            campaigns, and community support systems to tackle these issues.
          </p>

          {/* Activities and Events During Mental Health Awareness Month
           */}

          <h3 className="text-2xl font-semibold mb-2 mt-6">
            Activities and Events During Mental Health Awareness Month
          </h3>
          <p className="text-base font-normal">
            Understanding the early signs of suicidal thoughts can make a
            difference. Watch for:
          </p>
          <ul className="list-decimal list-inside mt-3">
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Awareness Campaigns:
              </span>{" "}
              Social media initiatives using hashtags like{" "}
              <strong>#MentalHealthMatters </strong> and{" "}
              <strong>#BreakTheStigma </strong>encourage public dialogue.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Educational Webinars:
              </span>{" "}
              Mental health experts share insights into conditions like anxiety,
              depression, PTSD, and more.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Mental Health Screenings:
              </span>{" "}
              Free screenings are offered by clinics and organizations to help
              individuals identify and address issues early.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Community Support Groups:
              </span>{" "}
              Safe spaces for individuals to share experiences and find support.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Fundraising Events:
              </span>{" "}
              Walkathons, charity drives, and benefit concerts raise funds for
              mental health services.
            </li>
          </ul>

          {/* Recognizing the Early Signs of Mental Health Struggles */}

          <h3 className="text-2xl font-semibold mb-2 mt-6">
            Recognizing the Early Signs of Mental Health Struggles
          </h3>
          <p className="text-base font-normal">
            Understanding the warning signs can prevent worsening conditions.
            Look for:
          </p>
          <ul className="list-disc list-inside mt-3">
            <li className="text-base text-black leading-relaxed">
              Persistent sadness, anxiety, or mood swings.
            </li>
            <li className="text-base text-black leading-relaxed">
              Withdrawal from social interactions and activities.
            </li>
            <li className="text-base text-black leading-relaxed">
              Changes in sleep or eating habits.
            </li>
            <li className="text-base text-black leading-relaxed">
              Difficulty concentrating or completing tasks.
            </li>
            <li className="text-base text-black leading-relaxed">
              Physical symptoms like headaches or fatigue are linked to stress.
            </li>
          </ul>

          {/*Steps to Improve Mental Health */}

          <h3 className="text-2xl font-semibold mb-2 mt-6">
            Steps to Improve Mental Health
          </h3>

          <ul className="list-decimal list-inside mt-3">
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Practice Self-Care:
              </span>{" "}
              Incorporate exercise, mindfulness, and healthy routines into daily
              life.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Seek Support:
              </span>{" "}
              Connect with friends, family, or professional counselors when
              feeling overwhelmed.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Educate Yourself:
              </span>{" "}
              Learn about mental health to reduce stigma and support others
              effectively.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Advocate for Change:
              </span>{" "}
              Promote mental health policies that increase access to affordable
              care.
            </li>
          </ul>

          {/* How You Can Participate */}

          <h3 className="text-2xl font-semibold mb-2 mt-6">
            How You Can Participate
          </h3>

          <ul className="list-disc list-inside mt-3">
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Raise Awareness:
              </span>{" "}
              Share mental health resources and stories on social media.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Volunteer:
              </span>{" "}
              Join local mental health organizations in their initiatives.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Support Services:
              </span>{" "}
              Donate to mental health foundations in Pakistan or the USA.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Check In:
              </span>{" "}
              Regularly connect with loved ones and colleagues to promote
              emotional well-being.
            </li>
          </ul>

          {/* Call to Action */}

          <h3 className="text-2xl font-semibold mb-2 mt-6">Call to Action</h3>
          <p className="text-base font-normal">
            This <strong>Mental Health Awareness Month</strong>, let’s unite to
            promote mental well-being, inspire hope, and create a world where
            everyone can access the care they need. Together, we can break
            barriers, build bridges, and ensure mental health becomes a priority
            for all.
          </p>
          <p className="text-base font-normal">
            <strong>Resources for Support:</strong>
          </p>

          <ul className="list-decimal list-inside mt-3">
            <li className="text-base text-black leading-relaxed">
              In <strong>Pakistan</strong>, contact the{" "}
              <strong>Mental Health Helpline</strong> at <strong>1166</strong>{" "}
              or contac <strong>Rozan Counseling Service</strong>.
            </li>
            <li className="text-base text-black leading-relaxed">
              In the <strong>USA</strong>, call the National Mental Health
              Helpline at <strong>-800-662-HELP (4357)</strong>.
            </li>
          </ul>
          <p className="text-base font-normal">
            Let’s make mental health a shared responsibility, fostering a
            brighter and more supportive future for all.
          </p>
        </EventsText>
        <Pagination
          prevLink="/national-suicide-prevention"
          prevText="National Suicide Prevention Week"
          nextLink="/mental-health-day"
          nextText="World Mental Health Day"
        />
        <Footer />
      </div>
    </>
  );
}

export default NationalMental;
