import React from "react";
import TopNav from "../TopNav";
import Footer from "../Footer";
import Header from "../Comp/Header";
import EventsText from "../Comp/EventsText";
import defaultImage from "../../assets/Silhouette-banner-1.webp";
import EventDetails from "../Comp/EventDetails";
import Pagination from "../Comp/Pagination";
import img1 from "../../assets/depositphotos_95793324-stock-photo.jpg";

function Happiness() {
  return (
    <>
      <div>
        <TopNav transparent />
        <Header
          leftTitle="International Day of Happiness"
          breadcrumbTitle="Events"
        />
        <EventDetails
          eventImage={img1}
          title="Events"
          startDate="March 20"
          endDate="March 20"
          // doorTime="10:00 am"
          // status="Showing"
          address="Model Town, Haseeb Street, 42-43 B, Multan, Pakistan."
        />{" "}
        <EventsText>
          {/* International Day of Happiness */}

          <h3 className="text-2xl font-semibold mb-2 mt-4">
            International Day of Happiness
          </h3>
          <p className="text-base font-normal">
            The <strong>International Day of Happiness</strong> is a global
            celebration that transcends mere joy and good vibes. Observed
            annually on March 20, this day was established by the{" "}
            <strong>United Nations</strong> to highlight the importance of
            happiness and well-being as universal goals for individuals,
            communities, and nations alike.
          </p>
          <p className="text-base font-normal">
            While it may conjure images of carefree laughter and playful
            moments, the day carries a deeper purpose: to foster a global
            conversation about how we can create a kinder, more compassionate
            world where happiness becomes accessible to all.
          </p>

          {/* Why It Matters */}
          <h3 className="text-2xl font-semibold mb-2 mt-6">
            The Significance of Happiness
          </h3>
          <p className="text-base font-normal">
            Happiness isn’t just about fleeting emotions; it is a state of
            well-being that contributes to personal and societal progress. The{" "}
            <strong>United Nations</strong> recognizes its importance through
            the <strong>World Happiness Report</strong>, which ranks countries
            based on factors like:
          </p>
          <ul className="list-disc list-inside mt-3">
            <li className="text-base text-black leading-relaxed">
              Social support
            </li>
            <li className="text-base text-black leading-relaxed">Income</li>
            <li className="text-base text-black leading-relaxed">
              Life expectancy
            </li>
            <li className="text-base text-black leading-relaxed">
              Freedom to make life choices
            </li>
            <li className="text-base text-black leading-relaxed">Generosity</li>
            <li className="text-base text-black leading-relaxed">
              Trust in government and institutions
            </li>
          </ul>
          <p className="text-base font-normal">
            Promoting happiness aligns with the{" "}
            <strong>Sustainable Development Goals (SDGs)</strong>, emphasizing
            the need for equality, environmental stewardship, and the
            eradication of poverty to create a world where everyone has the
            opportunity to thrive.
          </p>

          {/* Why Celebrate Happiness? */}
          <h3 className="text-2xl font-semibold mb-2 mt-6">
            Why Celebrate Happiness?
          </h3>
          <p className="text-base font-normal">
            The International Day of Happiness serves as a reminder that mental
            health, emotional well-being, and meaningful connections are as
            critical to a fulfilling life as material wealth. This day
            encourages individuals, communities, and governments to:
          </p>
          <ul className="list-disc list-inside mt-3">
            <li className="text-base text-black leading-relaxed">
              Reflect on what truly matters in life.
            </li>
            <li className="text-base text-black leading-relaxed">
              Recognize the value of acts of kindness and compassion.
            </li>
            <li className="text-base text-black leading-relaxed">
              Work towards policies that prioritize collective well-being.
            </li>
          </ul>

          {/* How to Get Involved */}

          <h3 className="text-2xl font-semibold mb-2 mt-6">
            How to Get Involved
          </h3>

          <ul className="list-disc list-inside mt-3">
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Spread Positivity:
              </span>{" "}
              Share uplifting messages or acts of kindness in your community.
              Small gestures, like a smile or helping hand, can brighten
              someone’s day.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Reflect and Practice Gratitude:
              </span>{" "}
              Take a moment to appreciate the good in your life and express
              gratitude to those who support you.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Join Events:
              </span>{" "}
              Participate in activities promoting happiness and well-being, from
              meditation workshops to community cleanups.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Advocate for Change:
              </span>{" "}
              Support initiatives that address inequality, mental health, and
              environmental sustainability.
            </li>
          </ul>

          {/* Event Hashtags*/}

          <h3 className="text-2xl font-semibold mb-2 mt-6">Event Hashtags</h3>
          <p className="text-base font-normal">
            Show your support for{" "}
            <strong>International Day of Happiness</strong> by participating in
            online discussions and using the following hashtag to inspire
            others.
          </p>
          <ul>
            <li className="text-base font-semibold mt-4 mb-2">
              {" "}
              #InternationalDayOfHappines
            </li>
            <li className="text-base font-semibold mb-2">#HappinessForAll</li>
            <li className="text-base font-semibold mb-2">#ChooseHappiness</li>
          </ul>

          {/* The Global Perspective */}

          <h3 className="text-2xl font-semibold mb-2 mt-6">
            The Global Perspective
          </h3>
          <p className="text-base font-normal">
            Countries worldwide celebrate this day with diverse initiatives,
            from educational campaigns on emotional intelligence to public
            events promoting health and mindfulness. The central message remains
            universal:{" "}
            <strong>
              happiness is a shared responsibility and a collective achievement.
            </strong>
          </p>

          {/* Call to Action */}

          <h3 className="text-2xl font-semibold mb-2 mt-6">Call to Action</h3>
          <p className="text-base font-normal">
            This <strong>March 20s</strong>, let’s unite in celebrating the
            power of happiness. Whether through small, meaningful acts or
            large-scale initiatives, we can all contribute to a brighter, more
            compassionate world.
          </p>

          <p className="text-base font-normal">
            Happiness isn’t just a feeling; it’s a way of life — one worth
            sharing. Let’s make it count.
          </p>
        </EventsText>
        <Pagination
          prevLink="/child-awareness"
          prevText="Children's Mental Health Awareness Week"
          nextLink="/mental-health-awareness"
          nextText="Mental Health Awareness Week"
        />
        <Footer />
      </div>
    </>
  );
}

export default Happiness;
