import React from "react";
import TopNav from "../TopNav";
import Footer from "../Footer";
import Header from "../Comp/Header";
import EventsText from "../Comp/EventsText";
import defaultImage from "../../assets/Silhouette-banner-1.webp";
import EventDetails from "../Comp/EventDetails";
import Pagination from "../Comp/Pagination";
import img1 from "../../assets/metaphor-memory_23-2151240836.jpg";

function Schizophrenia() {
  return (
    <>
      <div>
        <header>
          <TopNav transparent aria-label="Top Navigation" />
          <Header
            leftTitle="Schizophrenia Awareness"
            breadcrumbTitle="Events"
            aria-labelledby="header-title"
          />
        </header>

        <main>
          <section aria-labelledby="event-details-section">
            <EventDetails
              eventImage={img1}
              title="Events"
              startDate="July 25"
              endDate="July 25"
              // doorTime="10:00 am"
              // status="Showing"
              address="Model Town, Haseeb Street, 42-43 B, Multan, Pakistan."
              aria-labelledby="event-details"
            />
          </section>

          <section aria-labelledby="events-text-section">
            <EventsText>
              {/* National Schizophrenia Awareness Day */}

              <h3 className="text-2xl font-semibold mb-2 mt-4">
                National Schizophrenia Awareness Day
              </h3>
              <p className="text-base font-normal">
                National Schizophrenia Awareness Day is observed annually on
                July 25 to raise awareness about schizophrenia, a complex and
                often misunderstood mental health condition. The day focuses on
                reducing stigma, improving understanding, and advocating for
                better support systems for individuals living with schizophrenia
                and their families.
              </p>
              <p className="text-base font-normal">
                This observance is a vital opportunity to educate the public,
                promote empathy, and highlight the need for comprehensive care
                and resources for those affected.
              </p>

              {/* Importance of National Schizophrenia Awareness Day */}
              <h3 className="text-2xl font-semibold mb-2 mt-6">
                Importance of National Schizophrenia Awareness Day
              </h3>
              <p className="text-base font-normal">
                Schizophrenia Awareness Day serves as a reminder of the
                challenges faced by those living with the condition and the
                importance of providing a supportive environment. This day
                encourages:
              </p>
              <ul className="list-disc list-inside mt-3">
                <li className="text-base text-black leading-relaxed">
                  <span className="text-base mb-2 text-black font-semibold">
                    Awareness:
                  </span>{" "}
                  Educating the public about the symptoms, causes, and impact of
                  schizophrenia.
                </li>
                <li className="text-base text-black leading-relaxed">
                  <span className="text-base mb-2 text-black font-semibold">
                    Stigma Reduction:
                  </span>{" "}
                  Challenging myths and misconceptions to promote acceptance and
                  understanding.
                </li>
                <li className="text-base text-black leading-relaxed">
                  <span className="text-base mb-2 text-black font-semibold">
                    Advocacy:
                  </span>{" "}
                  Advocating for better mental health policies and services to
                  support individuals with schizophrenia.
                </li>
                <li className="text-base text-black leading-relaxed">
                  <span className="text-base mb-2 text-black font-semibold">
                    Support for Caregivers:
                  </span>{" "}
                  Recognizing the role of families and caregivers and addressing
                  their needs.
                </li>
              </ul>

              {/*  What is Schizophrenia? */}
              <h3 className="text-2xl font-semibold mb-2 mt-6">
                What is Schizophrenia?
              </h3>
              <p className="text-base font-normal">
                Schizophrenia is a chronic mental health condition that affects
                how a person thinks, feels, and behaves. It is often
                characterized by episodes of psychosis, which may include
                hallucinations, delusions, and disorganized thinking. Key facts
                about schizophrenia include:
              </p>
              <ul className="list-disc list-inside mt-3">
                <li className="text-base text-black leading-relaxed">
                  <span className="text-base mb-2 text-black font-semibold">
                    Prevalence:
                  </span>{" "}
                  Schizophrenia affects approximately 1 in 300 people worldwide.
                </li>
                <li className="text-base text-black leading-relaxed">
                  <span className="text-base mb-2 text-black font-semibold">
                    Onset:
                  </span>{" "}
                  Symptoms typically emerge in late adolescence or early
                  adulthood.
                </li>
                <li className="text-base text-black leading-relaxed">
                  <span className="text-base mb-2 text-black font-semibold">
                    Symptoms:
                  </span>{" "}
                  Common symptoms include auditory or visual hallucinations,
                  delusions, difficulty concentrating, and social withdrawal.
                </li>
                <li className="text-base text-black leading-relaxed">
                  <span className="text-base mb-2 text-black font-semibold">
                    Treatability:
                  </span>{" "}
                  With proper treatment, including medication, therapy, and
                  support, many individuals with schizophrenia can lead
                  fulfilling lives.
                </li>
              </ul>

              {/* Challenges Faced by Individuals with Schizophrenia */}

              <h3 className="text-2xl font-semibold mb-2 mt-6">
                Challenges Faced by Individuals with Schizophrenia
              </h3>

              <ul className="list-decimal list-inside mt-3">
                <li className="text-base text-black leading-relaxed">
                  <span className="text-base mb-2 text-black font-semibold">
                    Stigma and Misunderstanding:
                  </span>{" "}
                  Many people with schizophrenia face social stigma and
                  discrimination, which can hinder their ability to seek
                  treatment and build supportive relationships.
                </li>
                <li className="text-base text-black leading-relaxed">
                  <span className="text-base mb-2 text-black font-semibold">
                    Access to Care:
                  </span>{" "}
                  Limited access to mental health services and resources remains
                  a significant barrier in many regions.
                </li>
                <li className="text-base text-black leading-relaxed">
                  <span className="text-base mb-2 text-black font-semibold">
                    Isolation:
                  </span>{" "}
                  Social withdrawal and misunderstandings often lead to feelings
                  of loneliness and alienation.
                </li>
                <li className="text-base text-black leading-relaxed">
                  <span className="text-base mb-2 text-black font-semibold">
                    Economic Impact:
                  </span>{" "}
                  The condition can affect a person's ability to work, leading
                  to financial instability.
                </li>
              </ul>

              {/* Promoting Awareness and Support */}

              <h3 className="text-2xl font-semibold mb-2 mt-6">
                Promoting Awareness and Support
              </h3>
              <p className="text-base font-normal">
                National Schizophrenia Awareness Day calls for collective
                efforts to improve the lives of individuals with schizophrenia.
                Here are ways to contribute:
              </p>
              <ul className="list-disc list-inside mt-3">
                <li className="text-base text-black leading-relaxed">
                  <span className="text-base mb-2 text-black font-semibold">
                    Educate Yourself and Others:
                  </span>{" "}
                  Learn about schizophrenia and share accurate information to
                  dispel myths.
                </li>
                <li className="text-base text-black leading-relaxed">
                  <span className="text-base mb-2 text-black font-semibold">
                    Advocate for Better Care:
                  </span>{" "}
                  Support initiatives that improve access to mental health
                  services and research funding.
                </li>
                <li className="text-base text-black leading-relaxed">
                  <span className="text-base mb-2 text-black font-semibold">
                    Foster Inclusion:
                  </span>{" "}
                  Create safe and supportive environments for individuals with
                  schizophrenia to feel valued and included.
                </li>
                <li className="text-base text-black leading-relaxed">
                  <span className="text-base mb-2 text-black font-semibold">
                    Engage in Conversations
                  </span>{" "}
                  Encourage open and respectful dialogues about mental health to
                  reduce stigma.
                </li>
              </ul>

              {/* Call to Action */}

              <h3 className="text-2xl font-semibold mb-2 mt-6">
                Call to Action
              </h3>
              <p className="text-base font-normal">
                On National Schizophrenia Awareness Day, take a moment to
                reflect on the importance of mental health and the challenges
                faced by those living with schizophrenia. By fostering
                understanding, supporting mental health organizations, and
                advocating for better care, we can create a more inclusive and
                compassionate society.
              </p>

              {/* Event Hashtags*/}

              <h3 className="text-2xl font-semibold mb-2 mt-6">
                Event Hashtags
              </h3>
              <p className="text-base font-normal">
                Join the conversation online and show your support for
                schizophrenia awareness by using these hashtags:
              </p>
              <ul>
                <li className="text-base font-semibold mt-4 mb-2">
                  #SchizophreniaAwarenessDay
                </li>
                <li className="text-base font-semibold mb-2">#EndTheStigma</li>
                <li className="text-base font-semibold mb-2">
                  #SupportMentalHealth
                </li>
                <li className="text-base font-semibold mb-2">
                  #SchizophreniaSupport
                </li>
                <li className="text-base font-semibold mb-2">
                  #MentalHealthMatters
                </li>
              </ul>
            </EventsText>
          </section>

          <section aria-labelledby="pagination-section">
            <Pagination
              prevLink="/eating-disorders-awareness"
              prevText="Eating Disorder Awareness Week"
              nextLink="/bipolar-awareness"
              nextText="World Biopolar Day"
              aria-label="Event pagination"
            />
          </section>
        </main>

        <footer>
          <Footer aria-label="Footer" />
        </footer>
      </div>
    </>
  );
}

export default Schizophrenia;
