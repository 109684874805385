import React from "react";
import TopNav from "../TopNav";
import Footer from "../Footer";
import Form from "../Comp/Form";
import Header from "../Comp/Header";
import defaultImage from "../../assets/Silhouette-banner-1.webp";
import EventDetails from "../Comp/EventDetails";
import Pagination from "../Comp/Pagination";
import img1 from "../../assets/istockphoto-1090945010-612x612.jpg";

function Special() {
  return (
    <>
      <div>
        <TopNav transparent />
        <Header
          leftTitle="Special-Needs Disorders"
          breadcrumbTitle="Expertise"
        />
        {/* Image */}
        <div className="flex justify-center items-center py-10 mt-4 px-4 sm:px-8 md:px-16 lg:px-32 h-full w-full">
          <img
            src={img1}
            alt="Centered Example"
            className="object-fill rounded-xl shadow-xl shadow-[#f7ece1]"
          />
        </div>

        {/* Text */}
        <div className="py-10 px-4 sm:px-8 md:px-16 lg:px-32">
          <div className="max-w-4xl mx-auto">
            <h1 className="text-2xl font-extrabold mb-6">
              Special Needs Disorder
            </h1>
            <p className="text-black mb-4">
              <strong>Embracing and Empowering Special Needs</strong>
            </p>
            <p className="text-black mb-6">
              Every child is extraordinary in their own way, and children with
              special needs bring unique perspectives, abilities, and strengths
              to the world. At Mindful Connection Hub, we are dedicated to
              creating a space where these children and their families feel
              understood, supported, and empowered. Our approach focuses on
              fostering growth, building confidence, and celebrating milestones,
              no matter how big or small.
            </p>

            <h4 className="text-lg font-semibold mb-3">
              What Are Special Needs Disorders?
            </h4>
            <p className="text-black mb-6">
              Special needs encompass a wide range of conditions that may affect
              a child’s physical, cognitive, emotional, or social development.
              These can include:
            </p>
            <ul className="list-disc list-inside text-black mb-6">
              <li>Autism Spectrum Disorder (ASD)</li>
              <li>Down Syndrome</li>
              <li>Cerebral Palsy</li>
              <li>Learning Disabilities</li>
              <li>Speech and Language Disorders</li>
              <li>Sensory Processing Disorders</li>
            </ul>
            <p className="text-black mb-6">
              Each child’s journey is different, and we believe in tailoring our
              support to meet their individual needs.
            </p>

            <h4 className="text-lg font-semibold mb-3">
              Our Comprehensive Services
            </h4>
            <p className="text-black mb-6">
              At Mindful Connection Hub, we provide a range of services designed
              to help children with special needs thrive in a safe, inclusive,
              and nurturing environment.
            </p>

            <h5 className="text-md font-semibold mb-3">
              1. Personalized Assessments
            </h5>
            <p className="text-black mb-6">
              Understanding the unique challenges and strengths of each child is
              the first step toward creating an effective support plan. Our
              detailed assessments provide insights into their developmental
              needs and pave the way for meaningful interventions.
            </p>

            <h5 className="text-md font-semibold mb-3">
              2. Individualized Therapy Plans
            </h5>
            <p className="text-black mb-6">
              No two children are the same, and neither are our therapy plans.
              We design customized programs to address areas such as speech and
              communication, motor skills, sensory integration, and behavioral
              goals.
            </p>

            <h5 className="text-md font-semibold mb-3">
              3. Family-Centered Support
            </h5>
            <p className="text-black mb-6">
              We believe that families play an essential role in a child’s
              growth. Our team works closely with parents and caregivers,
              offering guidance, training, and resources to reinforce progress
              at home.
            </p>

            <h5 className="text-md font-semibold mb-3">
              4. Group Activities and Social Integration
            </h5>
            <p className="text-black mb-6">
              Through engaging group activities, we help children build social
              connections, develop teamwork skills, and experience the joy of
              shared learning.
            </p>

            <h4 className="text-lg font-semibold mb-3">
              Why Choose Mindful Connection Hub?
            </h4>
            <p className="text-black mb-6">
              We know that the journey can sometimes feel overwhelming. That’s
              why we are here to walk alongside you, offering:
            </p>
            <ul className="list-disc list-inside text-black mb-6">
              <li>
                <strong>Compassionate Care:</strong> Every interaction is
                grounded in empathy and respect for your child’s individuality.
              </li>
              <li>
                <strong>Expertise You Can Trust:</strong> Our multidisciplinary
                team includes skilled psychologists, therapists, and educators
                with years of experience.
              </li>
              <li>
                <strong>A Safe and Inclusive Space:</strong> We foster an
                environment where every child feels accepted, valued, and
                encouraged.
              </li>
            </ul>

            <h4 className="text-lg font-semibold mb-3">
              Early Intervention Matters
            </h4>
            <p className="text-black mb-6">
              Research shows that early intervention can have a profound impact
              on a child’s development. If you notice signs such as delayed
              speech, difficulty interacting with peers, or challenges in motor
              skills, reaching out for professional support can make all the
              difference.
            </p>

            <h4 className="text-lg font-semibold mb-3">
              Celebrate Every Achievement
            </h4>
            <p className="text-black mb-6">
              At Mindful Connection Hub, we believe that every step forward—no
              matter how small—is a victory worth celebrating. Together, we can
              help your child unlock their potential and live a life filled with
              confidence, joy, and purpose.
            </p>

            <p className="text-black mb-6">
              Let’s build brighter futures, one milestone at a time. Contact us
              today to learn more about how we can support your child’s unique
              journey.
            </p>
          </div>
        </div>

        <Pagination
          prevLink="/childhood-disorder"
          prevText="Childhood Disorder"
          nextLink="/counseling-exp"
          nextText="Counseling"
        />
        <Form />
        <Footer />
      </div>
    </>
  );
}

export default Special;
