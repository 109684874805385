import React from "react";
import TopNav from "../TopNav";
import Footer from "../Footer";
import Header from "../Comp/Header";
import EventsText from "../Comp/EventsText";
import defaultImage from "../../assets/Silhouette-banner-1.webp";
import EventDetails from "../Comp/EventDetails";
import Pagination from "../Comp/Pagination";
import img1 from "../../assets/hand-drawn-ptsd.jpg";

function PtsdAwareness() {
  return (
    <>
      <div>
        <TopNav transparent />
        <Header
          leftTitle="National PTSD Awareness Month"
          breadcrumbTitle="Events"
        />
        <EventDetails
          eventImage={img1}
          title="Events"
          startDate="June 1st"
          endDate="June 30th"
          // doorTime="10:00 am"
          // status="Showing"
          address="Model Town, Haseeb Street, 42-43 B, Multan, Pakistan."
        />
        <EventsText>
          {/* PTSD Awareness Month */}

          <h3 className="text-2xl font-semibold mb-2 mt-4">
            PTSD Awareness Month
          </h3>
          <p className="text-base font-normal">
            Every year, the month of June is observed as{" "}
            <strong>
              Post-Traumatic Stress Disorder (PTSD) Awareness Month
            </strong>
            , a time dedicated to increasing public understanding of PTSD and
            supporting those affected. This month-long observance emphasizes the
            importance of recognizing PTSD, reducing stigma, and encouraging
            individuals to seek help.
          </p>
          <p className="text-base font-normal">
            PTSD Awareness Month provides an opportunity to foster empathy and
            promote the mental health resources needed to assist individuals
            coping with this challenging condition.
          </p>

          {/* What is PTSD? */}
          <h3 className="text-2xl font-semibold mb-2 mt-6">What is PTSD?</h3>
          <p className="text-base font-normal">
            <strong>Post-Traumatic Stress Disorder (PTSD)</strong> is a mental
            health condition that can develop after experiencing or witnessing a
            traumatic event. These events may include combat, violent crimes,
            serious accidents, natural disasters, or personal assaults.
          </p>
          <p className="text-base font-normal">
            According to the{" "}
            <strong>American Psychological Association (APA)</strong>, PTSD is
            classified as an anxiety disorder characterized by the following:
          </p>
          <ul className="list-disc list-inside mt-3">
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Flashbacks:
              </span>{" "}
              Intrusive, distressing memories or reliving the traumatic event.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Nightmares:
              </span>{" "}
              Intense dreams related to the trauma, often accompanied by night
              sweats.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Avoidance:
              </span>{" "}
              Efforts to avoid reminders of the trauma, including people,
              places, or situations.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Hyperarousal:
              </span>{" "}
              Increased anxiety, irritability, or being easily startled.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Negative Mood:
              </span>{" "}
              Feelings of detachment, guilt, or emotional numbness.
            </li>
          </ul>

          {/*  Importance of PTSD Awareness Month */}
          <h3 className="text-2xl font-semibold mb-2 mt-6">
            Importance of PTSD Awareness Month
          </h3>
          <p className="text-base font-normal">
            This awareness month serves several key purposes:
          </p>
          <ul className="list-decimal list-inside mt-3">
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Reducing Stigma:
              </span>{" "}
              Educating the public about PTSD helps challenge misconceptions and
              stereotypes.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Encouraging Early Intervention:
              </span>{" "}
              Promoting awareness allows individuals to recognize symptoms and
              seek help sooner.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Supporting Survivors:
              </span>{" "}
              Demonstrating solidarity with those affected by PTSD and their
              families.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Advocating for Better Resources:
              </span>{" "}
              Raising awareness helps secure funding and resources for mental
              health services and research.
            </li>
          </ul>

          {/* Key Facts About PTSD */}

          <h3 className="text-2xl font-semibold mb-2 mt-6">
            Key Facts About PTSD
          </h3>

          <ul className="list-disc list-inside mt-3">
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Prevalence:
              </span>{" "}
              Around <strong>6% of the population</strong> will experience PTSD
              at some point in their lives.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Risk Factors:
              </span>{" "}
              While trauma is the root cause, factors like a history of mental
              illness, lack of social support, or repeated exposure to trauma
              increase vulnerability.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Demographics:
              </span>{" "}
              PTSD affects people of all ages, but is particularly common among
              veterans, first responders, and trauma survivors.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Treatment:
              </span>{" "}
              Effective treatments include therapy (such as Cognitive Behavioral
              Therapy and EMDR), medications, and support groups.
            </li>
          </ul>

          {/* Recognizing Symptoms of PTSD */}

          <h3 className="text-2xl font-semibold mb-2 mt-6">
            Recognizing Symptoms of PTSD
          </h3>
          <p className="text-base font-normal">
            It’s important to understand the symptoms of PTSD, which may
            include:
          </p>
          <ul className="list-disc list-inside mt-3">
            <li className="text-base text-black leading-relaxed">
              Reliving the trauma through intrusive thoughts, flashbacks, or
              nightmares.
            </li>
            <li className="text-base text-black leading-relaxed">
              Avoiding situations, people, or places that trigger memories of
              the trauma.
            </li>
            <li className="text-base text-black leading-relaxed">
              Persistent negative changes in mood, including feelings of
              hopelessness, guilt, or emotional detachment.
            </li>
            <li className="text-base text-black leading-relaxed">
              Increased irritability, difficulty sleeping, or being on edge.
            </li>
          </ul>

          {/* Promoting Awareness and Support*/}

          <h3 className="text-2xl font-semibold mb-2 mt-6">
            Promoting Awareness and Support
          </h3>
          <p className="text-base font-normal">
            PTSD Awareness Month encourages individuals and communities to come
            together and support those living with PTSD. Here’s how you can
            help:
          </p>
          <ul className="list-disc list-inside mt-3">
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Educate Yourself and Others:
              </span>{" "}
              Learn about PTSD and share accurate information to reduce stigma.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Offer Support:
              </span>{" "}
              Listen to and validate the experiences of those affected.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Encourage Treatment:
              </span>{" "}
              Help individuals connect with mental health resources and
              professionals.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Advocate for Policies:
              </span>{" "}
              Support initiatives that improve access to mental health care and
              PTSD research funding.
            </li>
          </ul>

          {/* Call to Action */}

          <h3 className="text-2xl font-semibold mb-2 mt-6">Call to Action</h3>
          <p className="text-base font-normal">
            During <strong>PTSD Awareness Month</strong>, let’s honor the
            courage of those living with PTSD by raising awareness, fostering
            understanding, and advocating for change. Together, we can build a
            world that supports mental health and offers hope to those affected.
          </p>

          {/* Event Hashtags*/}

          <h3 className="text-2xl font-semibold mb-2 mt-6">Event Hashtags</h3>
          <p className="text-base font-normal">
            Join the movement to spread awareness about PTSD on social media
            using these hashtags:
          </p>
          <ul>
            <li className="text-base font-semibold mt-4 mb-2">
              #PTSDAwarenessMonth
            </li>
            <li className="text-base font-semibold mb-2">#EndTheStigma</li>
            <li className="text-base font-semibold mb-2">
              #SupportPTSDRecovery
            </li>
            <li className="text-base font-semibold mb-2">
              #TraumaInformedCare
            </li>
            <li className="text-base font-semibold mb-2">
              #MentalHealthMatters
            </li>
            <li className="text-base font-semibold mb-2">#HealingFromTrauma</li>
            <li className="text-base font-semibold mb-2">#PTSDHelp</li>
            <li className="text-base font-semibold mb-2">
              #TogetherForMentalHealth
            </li>
          </ul>
        </EventsText>
        <Pagination
          prevLink="/bipolar-awareness"
          prevText="World Biopolar Day"
          nextLink="/child-awareness"
          nextText="Children's Mental Health Awareness Week"
        />
        <Footer />
      </div>
    </>
  );
}

export default PtsdAwareness;
