import React from "react";
import TopNav from "../TopNav";
import Footer from "../Footer";
import Header from "../Comp/Header";
import EventsText from "../Comp/EventsText";
import defaultImage from "../../assets/Silhouette-banner-1.webp";
import EventDetails from "../Comp/EventDetails";
import Pagination from "../Comp/Pagination";
import img1 from "../../assets/mental-health-awareness.jpg";

function MentalHealth() {
  return (
    <>
      <div>
        <TopNav transparent />
        <Header
          leftTitle="Mental Health Awareness Week"
          breadcrumbTitle="Events"
        />
        <EventDetails
          eventImage={img1}
          title="Events"
          startDate="May 12"
          endDate="May 18"
          // doorTime="10:00 am"
          // status="Showing"
          address="Model Town, Haseeb Street, 42-43 B, Multan, Pakistan."
        />
        <EventsText>
          {/*  Mental Health Awareness Week */}

          <h3 className="text-2xl font-semibold mb-2 mt-4">
            Mental Health Awareness Week
          </h3>
          <p className="text-base font-normal">
            <strong>Mental Health Awareness Week</strong> is a yearly event
            dedicated to raising awareness about mental health, breaking down
            stigmas, and encouraging open conversations about mental well-being.
            Taking place from May 12 to May 18, 2025, this week aims to
            spotlight critical issues affecting mental health and inspire
            positive change at individual and societal levels.
          </p>

          {/* The Importance of Mental Health Awareness */}
          <h3 className="text-2xl font-semibold mb-2 mt-6">
            The Importance of Mental Health Awareness
          </h3>
          <p className="text-base font-normal">
            1 in 4 people will experience a mental health issue at some point in
            their lives. Yet, stigma, lack of awareness, and inadequate access
            to support often prevent people from seeking help.{" "}
            <strong>Mental Health Awareness Week</strong> provides an
            opportunity to:
          </p>

          <ul className="list-disc list-inside mt-3">
            <li className="text-base text-black leading-relaxed">
              Educate the public on common mental health challenges.
            </li>
            <li className="text-base text-black leading-relaxed">
              Encourage individuals to prioritize their mental well-being.
            </li>
            <li className="text-base text-black leading-relaxed">
              Advocate for policies that ensure equitable access to mental
              health services.
            </li>
            <li className="text-base text-black leading-relaxed">
              Foster a culture of understanding and empathy.
            </li>
          </ul>

          {/*  Key Activities*/}
          <h3 className="text-2xl font-semibold mb-2 mt-6">Key Activities</h3>
          <p className="text-base font-normal">
            Mental Health Awareness Week 2025 will feature:
          </p>
          <ul className="list-decimal list-inside mt-3">
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Workshops and Webinars:
              </span>{" "}
              Expert-led discussions on managing stress, improving resilience,
              and recognizing signs of mental health issues.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Community Events:
              </span>{" "}
              Support groups, mindfulness sessions, and local gatherings to
              connect people with resources.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Fundraising Initiatives:
              </span>{" "}
              Campaigns to raise funds for mental health organizations and
              services.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Social Media Campaigns:
              </span>{" "}
              preading awareness through hashtags like{" "}
              <strong>#MHAW2025, #MentalHealthMatters</strong>, and{" "}
              <strong>#BreakTheStigma.</strong>
            </li>
          </ul>

          {/* How You Can Participate */}

          <h3 className="text-2xl font-semibold mb-2 mt-6">
            How You Can Participate
          </h3>

          <ul className="list-decimal list-inside mt-3">
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Educate Yourself and Others:
              </span>{" "}
              Learn about mental health issues and share resources to support
              those in need.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Join or Host Events:
              </span>{" "}
              Participate in local or virtual events to foster community and
              raise awareness.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Practice Self-Care:
              </span>{" "}
              Take time to nurture your mental well-being through activities
              like meditation, journaling, or exercise.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Advocate for Change:
              </span>{" "}
              Support policies and initiatives aimed at improving mental health
              care accessibility.
            </li>
          </ul>

          {/* Mental Health Foundation’s Role*/}

          <h3 className="text-2xl font-semibold mb-2 mt-6">
            Mental Health Foundation’s Role
          </h3>
          <p className="text-base font-normal">
            As the event organizer, the{" "}
            <strong>Mental Health Foundation</strong> provides resources,
            toolkits, and expert guidance to help individuals and organizations
            get involved. Their efforts aim to create lasting impact by
            addressing root causes of mental health issues and empowering
            communities to thrive.
          </p>

          {/* Call to Action */}

          <h3 className="text-2xl font-semibold mb-2 mt-6">Call to Action</h3>
          <p className="text-base font-normal">
            Let’s use <strong>Mental Health Awareness Week 2025</strong> to make
            mental health a priority. By joining conversations, offering
            support, and advocating for systemic changes, we can work together
            to create a world where mental well-being is celebrated and
            accessible for all.
          </p>
          <p className="text-base font-normal">
            Take the first step today: reach out, listen, and share. Your
            actions can make a difference!
          </p>
        </EventsText>
        <Pagination
          prevLink="/happiness-day-awareness"
          prevText="International Day of Happiness"
          nextLink="/world-suicide-awareness"
          nextText="World Suicide Prevention Day"
        />
        <Footer />
      </div>
    </>
  );
}

export default MentalHealth;
