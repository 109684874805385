import React from "react";
import TopNav from "../TopNav";
import Footer from "../Footer";
import Header from "../Comp/Header";
import EventsText from "../Comp/EventsText";
import defaultImage from "../../assets/Silhouette-banner-1.webp";
import EventDetails from "../Comp/EventDetails";
import Pagination from "../Comp/Pagination";
import img1 from "../../assets/6.jpg";

function ChildrenWeek() {
  return (
    <>
      <div>
        <TopNav transparent />
        <Header
          leftTitle="Children's Mental Health Awareness Week"
          breadcrumbTitle="Events"
        />
        <EventDetails
          eventImage={img1}
          title="Events"
          startDate="February 3rd"
          endDate="February 9th"
          // doorTime="10:00 am"
          // status="Showing"
          address="Model Town, Haseeb Street, 42-43 B, Multan, Pakistan."
        />
        <EventsText>
          {/* Children’s Mental Health Week */}

          <h3 className="text-2xl font-semibold mb-2 mt-4">
            Children’s Mental Health Week
          </h3>
          <p className="text-base font-normal">
            <strong>Children’s Mental Health Week</strong> is an annual
            initiative dedicated to raising awareness about the importance of
            mental health among children and young people. Taking place from
            February 3rd to February 9th, this vital week focuses on encouraging
            conversations, fostering understanding, and promoting strategies to
            support the emotional well-being of younger generations.
          </p>

          {/* Why It Matters */}
          <h3 className="text-2xl font-semibold mb-2 mt-6">Why It Matters</h3>
          <p className="text-base font-normal">
            Recent statistics highlight the growing need for mental health
            awareness in children:
          </p>
          <ul className="list-disc list-inside mt-3">
            <li className="text-base text-black leading-relaxed">
              <strong>1 in 5 children and young people</strong> are estimated to
              have a probable mental health disorder.
            </li>
            <li className="text-base text-black leading-relaxed">
              Early intervention and support can significantly improve outcomes
              for children experiencing mental health challenges.
            </li>
          </ul>
          <p className="text-base font-normal">
            By participating in Children’s Mental Health Week, families,
            schools, and communities can come together to recognize the
            significance of emotional well-being and the role kindness plays in
            fostering resilience and connection.
          </p>

          {/*  The Power of Kindness */}
          <h3 className="text-2xl font-semibold mb-2 mt-6">
            The Power of Kindness
          </h3>
          <p className="text-base font-normal">
            One of the simplest yet most impactful ways to support children’s
            mental health is through kindness. Acts of kindness, no matter how
            small can:
          </p>
          <ul className="list-disc list-inside mt-3">
            <li className="text-base text-black leading-relaxed">
              Help children feel valued and understood.
            </li>
            <li className="text-base text-black leading-relaxed">
              Strengthen social connections and reduce feelings of isolation.
            </li>
            <li className="text-base text-black leading-relaxed">
              Create a positive ripple effect, improving overall well-being in
              communities.
            </li>
          </ul>
          <p className="text-base font-normal">
            Encouraging children to practice kindness not only nurtures their
            emotional health but also fosters empathy, cooperation, and a sense
            of belonging.
          </p>

          {/* How to Get Involved */}

          <h3 className="text-2xl font-semibold mb-2 mt-6">
            How to Get Involved
          </h3>

          <ul className="list-disc list-inside mt-3">
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Schools:
              </span>{" "}
              Organize activities focused on mental health, such as mindfulness
              sessions, kindness challenges, or classroom discussions.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Parents and Caregivers:
              </span>{" "}
              Talk openly with children about feelings, emotions, and ways to
              seek help if needed.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Community Groups:
              </span>{" "}
              Host workshops or events that promote positive mental health
              practices for children and families.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Social Media:
              </span>{" "}
              Spread awareness using hashtags like{" "}
              <strong>#ChildrensMentalHealthWeek</strong>,{" "}
              <strong>#KindnessMatters</strong>
            </li>
          </ul>

          {/* Supporting the Initiative */}

          <h3 className="text-2xl font-semibold mb-2 mt-6">
            Recognizing Symptoms of PTSD
          </h3>
          <p className="text-base font-normal">
            The Mindful Connection Hub,the organization behind Children’s Mental
            Health Week, is dedicated to providing mental health support in
            schools and empowering young people with the tools to thrive
            emotionally. By donating or volunteering, you can help ensure that
            essential resources and services reach children who need them most.
          </p>

          {/* Call to Action */}

          <h3 className="text-2xl font-semibold mb-2 mt-6">Call to Action</h3>
          <p className="text-base font-normal">
            As <strong>Children’s Mental Health Week approaches</strong>, let’s
            prepare to take meaningful steps toward prioritizing the mental
            health of our younger generation. Together, through kindness,
            understanding, and action, we can create an environment where
            children feel supported, safe, and heard.
          </p>
        </EventsText>
        <Pagination
          prevLink="/ptsd-awareness"
          prevText="National PTSD Awareness Week"
          nextLink="/happiness-day-awareness"
          nextText="International Day of Happiness"
        />
        <Footer />
      </div>
    </>
  );
}

export default ChildrenWeek;
