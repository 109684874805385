import React from "react";
import TopNav from "../TopNav";
import Footer from "../Footer";
import Header from "../Comp/Header";
import EventsText from "../Comp/EventsText";
import EventDetails from "../Comp/EventDetails";
import Pagination from "../Comp/Pagination";
import img1 from "../../assets/images (1).jpg";

function WorldSuicide() {
  return (
    <>
      <div>
        <TopNav transparent />
        <Header
          leftTitle="World Suicide Prevention Day"
          breadcrumbTitle="Events"
        />
        <EventDetails
          eventImage={img1}
          title="Events"
          startDate="September 10th"
          endDate="September 10th"
          // doorTime="10:00 am"
          // status="Showing"
          address="Model Town, Haseeb Street, 42-43 B, Multan, Pakistan."
        />
        <EventsText>
          {/*  World Suicide Prevention Day */}

          <h3 className="text-2xl font-semibold mb-2 mt-4">
            World Suicide Prevention Day
          </h3>
          <p className="text-base font-normal">
            <strong>World Suicide Prevention Day (WSPD)</strong> is a global
            event observed annually on September 10th to raise awareness about
            suicide, its devastating impact, and the importance of prevention.
            This day brings together individuals, communities, and organizations
            to encourage meaningful actions that support mental health and save
            lives.
          </p>

          {/* The Importance of Suicide Prevention */}
          <h3 className="text-2xl font-semibold mb-2 mt-6">
            The Importance of Suicide Prevention
          </h3>
          <p className="text-base font-normal">
            According to WHO, over 700,000 people die by suicide each year,
            making it one of the leading causes of death worldwide. Every loss
            represents not just an individual tragedy but a ripple effect
            impacting families, friends, and communities. WSPD emphasizes the
            need to:
          </p>

          <ul className="list-disc list-inside mt-3">
            <li className="text-base text-black leading-relaxed">
              Break the stigma surrounding suicide and mental health.
            </li>
            <li className="text-base text-black leading-relaxed">
              Equip individuals with tools and resources for early intervention.
            </li>
            <li className="text-base text-black leading-relaxed">
              Advocate for improved mental health support systems globally.
            </li>
          </ul>

          {/*  Recognizing Early Warning Signs*/}
          <h3 className="text-2xl font-semibold mb-2 mt-6">
            Recognizing Early Warning Signs
          </h3>
          <p className="text-base font-normal">
            Recognizing the signs of distress can be lifesaving. Common
            indicators include:
          </p>
          <ul className="list-decimal list-inside mt-3">
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Emotional Changes:
              </span>{" "}
              Extreme mood swings, persistent sadness, feelings of hopelessness,
              or emotional numbness.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Behavioral Changes:
              </span>{" "}
              Withdrawing from friends and family, losing interest in
              activities, or giving away personal belongings.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Verbal Cues:
              </span>{" "}
              Talking about feeling trapped, being a burden, or having no reason
              to live.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Physical Symptoms:
              </span>{" "}
              Changes in sleeping patterns, eating habits, or unexplained
              physical ailments.
            </li>
          </ul>
          <p className="text-base font-normal">
            If you notice these signs, it’s crucial to approach the person with
            compassion and encourage them to seek professional help.
          </p>

          {/* Preventive Measures */}

          <h3 className="text-2xl font-semibold mb-2 mt-6">
            Preventive Measures
          </h3>

          <ul className="list-decimal list-inside mt-3">
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Open Communication:
              </span>{" "}
              Foster an environment where individuals feel safe discussing their
              feelings.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Mental Health Awareness:
              </span>{" "}
              Educate yourself and others about mental health and suicide
              prevention.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Access to Support:
              </span>{" "}
              Ensure availability of mental health resources, including
              helplines, counseling, and support groups.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Promote Resilience:
              </span>{" "}
              Encourage activities that enhance coping skills, such as
              mindfulness, physical exercise, and social connections.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Restrict Means:
              </span>{" "}
              Reduce access to common methods of self-harm (e.g., firearms,
              toxic substances).
            </li>
          </ul>

          {/* Activities on WSPD */}

          <h3 className="text-2xl font-semibold mb-2 mt-6">
            Activities on WSPD
          </h3>

          <ul className="list-decimal list-inside mt-3">
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Lighting a Candle of Hope:
              </span>{" "}
              People worldwide light a candle near a window at 8:00 PM to honor
              lives lost and show support for those in distress.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Awareness Walks and Events:
              </span>{" "}
              Communities come together for walks, seminars, and workshops to
              spread awareness.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Social Media Campaigns:
              </span>{" "}
              Using hashtags like <strong>#WSPD2025</strong> and{" "}
              <strong>#HopeThroughAction</strong> to share resources and stories
              of resilience.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Fundraising and Advocacy:
              </span>{" "}
              Supporting organizations that provide mental health services and
              suicide prevention programs.
            </li>
          </ul>

          {/* Call to Action */}

          <h3 className="text-2xl font-semibold mb-2 mt-6">Call to Action</h3>
          <p className="text-base font-normal">
            This <strong>World Suicide Prevention Day </strong>, let’s remember
            that hope and help are always available. By recognizing the signs,
            offering support, and advocating for systemic change, we can save
            lives and create a compassionate world where no one feels alone in
            their struggles.
          </p>
          <p className="text-base font-normal">
            <strong>
              If you or someone you know is in crisis, don’t wait—reach out to a
              trusted person or a local helpline immediately. You are not alone.
            </strong>
          </p>
        </EventsText>
        <Pagination
          prevLink="/mental-health-awareness"
          prevText="Mental Health Awareness Week"
          nextLink="/national-suicide-prevention"
          nextText="National Suicide Prevention Week"
        />
        <Footer />
      </div>
    </>
  );
}

export default WorldSuicide;
