import React from "react";
import TopNav from "../TopNav";
import Footer from "../Footer";
import Header from "../Comp/Header";
import EventsText from "../Comp/EventsText";
import defaultImage from "../../assets/Silhouette-banner-1.webp";
import EventDetails from "../Comp/EventDetails";
import Pagination from "../Comp/Pagination";
import img1 from "../../assets/world-suicide-prevention.jpg";

function NationalSuicide() {
  return (
    <>
      <div>
        <TopNav transparent />
        <Header
          leftTitle="National Suicide Prevention Week"
          breadcrumbTitle="Events"
        />
        <EventDetails
          eventImage={img1}
          title="Events"
          startDate="September 10th"
          endDate="September 10th"
          // doorTime="10:00 am"
          // status="Showing"
          address="Model Town, Haseeb Street, 42-43 B, Multan, Pakistan."
        />
        <EventsText>
          {/*  National Suicide Prevention Week*/}

          <h3 className="text-2xl font-semibold mb-2 mt-4">
            National Suicide Prevention Week
          </h3>
          <p className="text-base font-normal">
            <strong>National Suicide Prevention Week (NSPW)</strong> is an
            annual event observed in the second week of September, aligning with{" "}
            <strong>World Suicide Prevention Day</strong> on September 10th.
            This week is dedicated to raising awareness about suicide
            prevention, reducing stigma around mental health, and empowering
            individuals to take action to save lives.
          </p>

          {/* The Significance of NSPW */}
          <h3 className="text-2xl font-semibold mb-2 mt-6">
            The Significance of NSPW
          </h3>
          <p className="text-base font-normal">
            Suicide is a growing public health crisis in both{" "}
            <strong>Pakistan </strong> and the <strong>USA </strong>. In
            Pakistan, over <strong>15,000 suicides </strong> are reported
            annually, with the actual number possibly higher due to
            underreporting. In the USA, suicide is the 10th leading cause of
            death, with over <strong>45,000 suicides </strong> recorded each
            year. National Suicide Prevention Week aims to:
          </p>

          <ul className="list-disc list-inside mt-3">
            <li className="text-base text-black leading-relaxed">
              <strong>Identify risk factors </strong> and warning signs.
            </li>
            <li className="text-base text-black leading-relaxed">
              <strong>Encourage open conversations </strong> about mental
              health.
            </li>
            <li className="text-base text-black leading-relaxed">
              <strong>Promote access to resources </strong> and professional
              support.
            </li>
          </ul>

          {/* Focus Areas for NSPW 2025 */}
          <h3 className="text-2xl font-semibold mb-2 mt-6">
            Focus Areas for NSPW 2025
          </h3>
          <ul className="list-decimal list-inside mt-3">
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Creating Connections:
              </span>{" "}
              Encouraging people to check in on loved ones and foster supportive
              relationships.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Education and Advocacy:
              </span>{" "}
              Sharing vital information about mental health, suicide prevention
              strategies, and crisis intervention.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Building Resilience:
              </span>{" "}
              Teaching coping mechanisms and stress management techniques to
              enhance emotional well-being.
            </li>
          </ul>

          {/* Recognizing the Warning Signs */}

          <h3 className="text-2xl font-semibold mb-2 mt-6">
            Recognizing the Warning Signs
          </h3>
          <p className="text-base font-normal">
            Understanding the early signs of suicidal thoughts can make a
            difference. Watch for:
          </p>
          <ul className="list-disc list-inside mt-3">
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Behavioral Changes:
              </span>{" "}
              Increased withdrawal, reckless behavior, or changes in sleeping
              and eating patterns.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Emotional Signs:
              </span>{" "}
              Persistent feelings of hopelessness, anger, or extreme mood
              swings.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Verbal Expressions:
              </span>{" "}
              Statements like "I can’t go on," or "The world would be better
              without me."
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Physical Symptoms:
              </span>{" "}
              Unexplained aches, fatigue, or decline in personal appearance.
            </li>
          </ul>
          <p className="text-base font-normal">
            If you notice these signs, approach the person with care and
            encourage them to seek help.
          </p>

          {/* Suicide Prevention Strategies */}

          <h3 className="text-2xl font-semibold mb-2 mt-6">
            Suicide Prevention Strategies
          </h3>

          <ul className="list-decimal list-inside mt-3">
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Talk About It:
              </span>{" "}
              Break the stigma surrounding suicide by initiating open and
              nonjudgmental conversations.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Provide Resources:
              </span>{" "}
              Share helpline numbers, counseling services, and online mental
              health tools.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Limit Access to Means:
              </span>{" "}
              Safeguard environments by reducing access to lethal means of
              self-harm.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Promote Mental Health Education:
              </span>{" "}
              Raise awareness about mental health in schools, workplaces, and
              communities.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Advocate for Policy Changes:
              </span>{" "}
              Support legislation that funds mental health services and
              prevention programs.
            </li>
          </ul>

          {/*Events and Activities During NSPW */}

          <h3 className="text-2xl font-semibold mb-2 mt-6">
            Events and Activities During NSPW
          </h3>

          <ul className="list-decimal list-inside mt-3">
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Awareness Walks:
              </span>{" "}
              Communities in both Pakistan and the USA organize walks to honor
              those lost to suicide and spread hope.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Webinars and Workshops:
              </span>{" "}
              Sessions led by mental health professionals to educate on suicide
              prevention techniques.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Social Media Campaigns:
              </span>{" "}
              SUsing hashtags like{" "}
              <strong>#NSPW2025, #StopSuicidePakistan,</strong> and{" "}
              <strong>#BeThe1To</strong> to share stories, resources, and
              messages of support.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Candlelight Vigils:
              </span>{" "}
              Memorial events to remember lives lost and show solidarity with
              survivors.
            </li>
          </ul>

          {/* How You Can Participate */}

          <h3 className="text-2xl font-semibold mb-2 mt-6">
            How You Can Participate
          </h3>

          <ul className="list-decimal list-inside mt-3">
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Check In:
              </span>{" "}
              Reach out to friends and family to see how they’re doing.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Volunteer:
              </span>{" "}
              Join local or national suicide prevention organizations in both
              countries.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Share Resources:
              </span>{" "}
              Post helpline numbers and educational content on social media.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Donate:
              </span>{" "}
              Contribute to organizations supporting mental health services in
              both Pakistan and the USA.
            </li>
          </ul>

          {/* Call to Action */}

          <h3 className="text-2xl font-semibold mb-2 mt-6">Call to Action</h3>
          <p className="text-base font-normal">
            This <strong>National Suicide Prevention Week </strong>, let’s unite
            to break the silence surrounding suicide and mental health
            struggles. By fostering connection, offering support, and promoting
            hope, we can create a society where everyone feels seen, heard, and
            valued.
          </p>
          <p className="text-base font-normal">
            <strong>If you or someone you know is in crisis:</strong>
          </p>

          <ul className="list-decimal list-inside mt-3">
            <li className="text-base text-black leading-relaxed">
              In <strong>Pakistan</strong>, contact the{" "}
              <strong>Mental Health Helpline</strong> at <strong>1166</strong>.
            </li>
            <li className="text-base text-black leading-relaxed">
              In the <strong>USA</strong>, call the{" "}
              <strong>National Suicide Prevention Lifeline</strong> at{" "}
              <strong>1-800-273-TALK (1-800-273-8255)</strong>, or text{" "}
              <strong>HELLO</strong> to <strong>741741</strong>.
            </li>
          </ul>
          <p className="text-base font-normal">Help is always available.</p>
        </EventsText>
        <Pagination
          prevLink="/world-suicide-awareness"
          prevText="World Suicide Prevention Day"
          nextLink="/national-mental-health-awareness"
          nextText="National Mental Health Awareness Month"
        />
        <Footer />
      </div>
    </>
  );
}

export default NationalSuicide;
